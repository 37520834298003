import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import Select from 'react-select';

import { ADD_TRANSFER_AGENT_ADDRESS } from '../../graphql/mutations/add-transfer-agent-address';
import { LIST_TRANSFER_AGENTS } from '../../graphql/queries/list-transfer-agents.query';
import Loader from '../loader/loader.component';

type AddTransferAgentAddressType = {
    onCompleteAddNewTransferAgentAddress: () => void;
}

const AddTransferAgentAddress: React.FC<AddTransferAgentAddressType> = ({ onCompleteAddNewTransferAgentAddress }) => {
    const [transferAgents, setTransferAgents] = useState([]);
    const [transferAgentUUID, SetTransferAgentUUID] = useState('');
    const [addressToAdd, SetAddressToAdd] = useState({
        addressLine1: "",
        city: "",
        state: "",
        zip: ""
    });

    const { loading: listTransferAgentsLoader, error: listTransferAgentsError } = useQuery(
        LIST_TRANSFER_AGENTS,
        {
            fetchPolicy: 'no-cache',
            onCompleted: (data: any) => {
                const transferAgentsList = data?.listTransferAgents?.filter((transferAgent: any) => transferAgent?.addresses?.length === 0);
                setTransferAgents(transferAgentsList)
            },
        }
    );

    const [
        addTransferAgentAddressFunc,
        { loading: addTransferAgentAddressLoader, error: addTransferAgentAddressError },
    ] = useMutation(
        ADD_TRANSFER_AGENT_ADDRESS,
        {
            variables: {
                transferAgentUUID,
                input: addressToAdd
            },
            onCompleted: data => onCompleteAddNewTransferAgentAddress()
        }
    );

    const onAddAddress = async () => {
        if (Object.values(addressToAdd).find(value => !value.trim())) return;
        if (!transferAgentUUID.trim()) return;

        try {
            await addTransferAgentAddressFunc();
        } catch (err) { }
    }

    const onAddressSelected = (id: string) => SetTransferAgentUUID(id);

    return <div className="shipping-info__change-box">
        <b className="shipping-info__details--title">
            Add Transfer Agentn Address
        </b>

        <div style={{ padding: "25px 0" }}>
            {listTransferAgentsLoader ? (
                <Loader size={30} />
            ) :
                <Select
                    className="basic-single"
                    styles={{
                        control: styles => ({ ...styles, minWidth: 350, height: 48 })
                    }}
                    onChange={data => onAddressSelected(data?.value)}
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    options={transferAgents?.filter((a: any) => a?.name)
                        .sort((a: any, b: any) => a?.name?.toUpperCase()?.localeCompare(b?.name?.toUpperCase()))
                        .map((a: any) => ({ value: a?.uuid, label: a?.name.toUpperCase() }))}
                />
            }
        </div>

        <hr />

        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 24,
            }}
        >
            <input
                className="input"
                style={{ maxWidth: "100%" }}
                placeholder="Street Address Line"
                name="addressLine1"
                value={addressToAdd.addressLine1}
                onChange={(e: any) => SetAddressToAdd({ ...addressToAdd, addressLine1: e.target.value })}
            />
        </div>
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 24,
            }}
        >
            <input
                className="input"
                style={{ maxWidth: 120 }}
                placeholder="City"
                name="city"
                value={addressToAdd.city}
                onChange={(e: any) => SetAddressToAdd({ ...addressToAdd, city: e.target.value })}
            />
            <input
                className="input"
                style={{ maxWidth: 100 }}
                placeholder="State"
                name="state"
                value={addressToAdd.state}
                onChange={(e: any) => SetAddressToAdd({ ...addressToAdd, state: e.target.value })}
            />
            <input
                className="input"
                style={{ maxWidth: 150 }}
                placeholder="Zip"
                name="zip"
                value={addressToAdd.zip}
                onChange={(e: any) => SetAddressToAdd({ ...addressToAdd, zip: e.target.value })}
            />
        </div>

        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 24,
            }}
        >
            <button className="shipping-info__btn" onClick={onAddAddress}>
                {addTransferAgentAddressLoader ? <Loader size={30} /> : 'Add'}
            </button>
        </div>
    </div>
};

export default AddTransferAgentAddress;