import { gql } from '@apollo/client';

export const GET_FILE_URL = gql`
  query getDocumentURL($documentUUID: String!, $transactionUUID: String!) {
    getDocumentURL(
      transactionUUID: $transactionUUID
      documentUUID: $documentUUID
    )
  }
`;
