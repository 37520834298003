/* eslint-disable no-use-before-define */
import { gql } from '@apollo/client';

export const ADD_USER_NOTE = gql`
  mutation addNote($userUUID: String, $content: String!) {
    addNote(userUUID: $userUUID, content: $content) {
      uuid
      content
      createdAt
    }
  }
`;
