import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($password: String!, $email: String!) {
    login(email: $email, password: $password) {
      email
      isEmailVerified
      status
      type
      paymentMethod
      roles
      firstName
      lastName
      mobile {
        uuid
        countryCode
        localNumber
        isVerified
      }
    }
  }
`;
