import { gql } from '@apollo/client';

export const LOCK_USER = gql`
  mutation lockUser($userUUID: String!) {
    lockUser(userUUID: $userUUID) {
      uuid
      firstName
      lastName
      email
      roles
      paymentMethod
      status
      dayOfBirth
      createdAt
      mobile {
        uuid
        countryCode
        localNumber
        isVerified
      }
      home {
        uuid
        addressLine1
        addressLine2
        zip
        country
        state
      }
      idologyLogs {
        uuid
        idNumber
        requestResponse
        status
      }
    }
  }
`;
