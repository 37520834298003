// DEV
// export const apolloURL =
//   'https://o6d3czwku3.execute-api.us-east-2.amazonaws.com/dev/admin';

//UAT
// export const apolloURL =
//   'https://1wczfnpg43.execute-api.us-east-2.amazonaws.com/uat/admin';

//PROD env
export const apolloURL =
  'https://consoleapi.esignatureguarantee.com/admin';
