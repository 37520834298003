import { gql } from '@apollo/client';

export const REFUND = gql`
  mutation refund($refundInCents: Float!, $transactionUUID: String!) {
    refund(transactionUUID: $transactionUUID, refundInCents: $refundInCents) {
      uuid
      status
      type
      provider
      totalDiscountsInCents
      totalMedallionCostInCents
      totalShippingCostInCents
      usaePay {
        details
      }  
    }
  }
`;
