import { gql } from '@apollo/client';

// addressLine1: String!
// city: String!
// state: String!
// zip: String!

export const ADD_TRANSFER_AGENT_ADDRESS = gql`
  mutation addTransferAgentAddress(
    $transferAgentUUID: String!
    $input: CreateAddressInput!
  ) {
    addTransferAgentAddress(
      transferAgentUUID: $transferAgentUUID
      input: $input
    ) {
      addressLine1
      addressLine2
      city
      country
      createdAt
      isDefault
      name
      state
      updatedAt
      uuid
      zip
    }
  }
`;
