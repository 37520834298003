import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GET_TRANSACTION } from '../../graphql/queries/get-transaction.query';
import { normelizeStatus } from '../../shared/helpers/normelize-status.helper';
import Loader from '../loader/loader.component';
import Table, { ButtonActionType } from '../table/table.component';
import {
  calculateCompletedMedallions,
  calculatePendingMedallions,
  completedTableMedallionsTitles,
  tableMedallionsTitles,
} from './medallions.helper';

import './medallions.scss';
import { GET_FEDEX_SERVICES } from '../../graphql/queries/get-fedex-services.query';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CHANGE_MEDALLIONS_STATUS } from '../../graphql/mutations/change-medallions-status.mutation';
import { GET_FEDEX_RATE } from '../../graphql/queries/get-fedex-rate.query';
import { CREATE_RETURN_LABEL } from '../../graphql/mutations/create-return-label.mutation';
import { useDispatch } from 'react-redux';
import {
  triggerErr,
  triggerSuccess,
} from '../../redux/slices/error-handling/error-handling-slice';
import { GET_ADJUSTED_RETURN_SHIPPING } from '../../graphql/queries/get-adjusted-return-shipping.query';
import { GET_USER } from '../../graphql/queries/get-user.query';
import { LIST_MEDALLIONS } from '../../graphql/queries/list-medallions.query';
import Certificates from './certificates.component';
import Checkbox from '@mui/material/Checkbox';
import { DENY_APPROVED_MEDALLIONS } from '../../graphql/mutations/deny-approved-medallions.mutation';

const Medallions = () => {
  const [transactionUUID, setTransactionUUID] = useState<any>('');
  const [transactionMedallions, setTransactionMedallions] = useState<any>([]);
  const [transactionCertificates, setTransactionCertificates] = useState<any>([]);
  const [certificateUUID, setCertificateUUID] = useState<any>(null);
  const [completed, setCompleted] = useState<boolean>(false);
  const [certificate, setCertificate] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);
  const [isBackPage, setIsBackPage] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState<boolean>(false);
  const [medallionAction, setMedallionAction] = useState<{ status: string; uuid: string[] }>({
    status: '',
    uuid: []
  });
  const [addressUUID, setAddressUUID] = useState<string>('');
  const [rate, setRate] = useState<number>(0);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [adminChosenAddress, setAdminChosenAddress] = useState<any>(null);
  const [userUUID, setUserUUID] = useState<any>('');
  const [user, setUser] = useState<any>({});
  const [medallionsChecked, setMedallionsChecked] = useState<any[]>([]);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const [getUser, { error: guError }] =
    useLazyQuery(GET_USER);

  React.useEffect(() => {
    if (userUUID === '') return;
    try {
      setLoadingProcess(true);
      getUser({
        variables: {
          userUUID: userUUID,
        },
        fetchPolicy: 'no-cache',
        onCompleted: async (data: any) => {
          setLoadingProcess(false);
          setUser(data?.getUser);
        },
      });
    } catch (err) {
      setLoadingProcess(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUUID]);

  React.useEffect(() => {
    setMedallionsChecked([]);
  }, [completed])

  React.useEffect(() => {
    let arr = location.pathname.split('/');
    const uuid = arr[arr?.length - 1];
    setTransactionUUID(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    if (transactionUUID === '') return;
    setLoadingProcess(true);
    fetchTransaction();
    getAdjustedAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionUUID]);

  React.useEffect(() => {
    if (!certificateUUID) return;
    fetchMedallions();
  }, [certificateUUID, page]);

  React.useEffect(() => {
    if (!certificateUUID) return;
    if (page === 1) {
      fetchMedallions();
      return;
    }
    setPage(1);
  }, [completed])

  const fetchTransaction = () => {
    try {
      getTransaction({
        variables: {
          transactionUUID: transactionUUID,
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
          setTransactionStatus(data?.getTransaction?.status)
          if (data?.getTransaction?.shippings[0]?.from?.uuid) {
            setAddressUUID(data?.getTransaction?.shippings[0]?.from?.uuid);
          }
          if (data?.getTransaction?.user?.uuid) {
            setUserUUID(data?.getTransaction?.user?.uuid);
          }
          setTransactionCertificates(data?.getTransaction?.certificates);
          setLoadingProcess(false);
        },
      });
    } catch (err) { }
  };

  const [getTransaction, { loading: getTransactionLoader, error: gtError }] = useLazyQuery(GET_TRANSACTION, {
    fetchPolicy: 'no-cache',
  });

  const [fetchMedallions, { loading: fetchMedallionsLoader, error: fmError }] =
    useLazyQuery(LIST_MEDALLIONS, {
      variables: {
        pagination: { limit: 10, page: page },
        transactionUUID: transactionUUID,
        certificateUUID: certificateUUID,
        completed
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        setTransactionMedallions(data?.listMedallions?.items);
        setIsNextPage(data?.listMedallions?.hasMore);
        setIsBackPage(page > 1);
      },
    });
  const [fedexService, setFedexService] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setFedexService(event.target.value as string);
  };

  React.useEffect(() => {
    if (adminChosenAddress && fedexService !== '') {
      getReturnRateFunc({
        variables: {
          fedexServiceUUID: fedexService,
          addressUUID: adminChosenAddress?.uuid
        }
      });
      return;
    };
    if (addressUUID !== '' && fedexService !== '') {
      getReturnRateFunc({
        variables: {
          fedexServiceUUID: fedexService,
          addressUUID: addressUUID
        }
      });
      return
    };
    if (user?.home && fedexService !== '') {
      getReturnRateFunc({
        variables: {
          fedexServiceUUID: fedexService,
          addressUUID: user?.home?.uuid
        }
      });
      return
    };

    if (fedexService !== '') {
      dispatch(triggerErr('No shipping address on record for this transaction'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fedexService]);

  const { data: getFedexServiceData, error: gfsError } =
    useQuery(GET_FEDEX_SERVICES);

  const [getReturnRateFunc, { loading: getReturnRateLoader, error: grrError }] =
    useLazyQuery(GET_FEDEX_RATE, {
      variables: {
        fedexServiceUUID: fedexService,
        addressUUID: adminChosenAddress?.uuid
          ? adminChosenAddress?.uuid
          : addressUUID,
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        setRate(data?.getReturnFedexRate?.totalNetCharge);
      },
    });

  const [changeMedallionsStatusFunc, { loading: changeMStatusLoader, error: cmsError }] = useMutation(
    CHANGE_MEDALLIONS_STATUS,
    {
      variables: {
        status: medallionAction.status,
        medallionUUIDs: medallionAction.uuid,
        transactionUUID: transactionUUID,
      },
      refetchQueries: [
        {
          query: GET_TRANSACTION,
          variables: { transactionUUID: transactionUUID },
        },
      ]
    }
  );

  const [denyApprovedMedallionsFunc, { loading: denyApprovedMedallionsLoader, error: denyApprovedMedallionsError }] = useMutation(
    DENY_APPROVED_MEDALLIONS,
    {
      variables: {
        medallionUUIDs: medallionAction.uuid,
        transactionUUID: transactionUUID,
      },
      refetchQueries: [
        {
          query: GET_TRANSACTION,
          variables: { transactionUUID: transactionUUID },
        },
      ]
    }
  );

  const [getAdjustedAddress] = useLazyQuery(GET_ADJUSTED_RETURN_SHIPPING, {
    variables: {
      transactionUUID: transactionUUID,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.getAdjustedReturnAddress) {
        setAdminChosenAddress(data?.getAdjustedReturnAddress);
      }
    },
  });

  const [
    generateReturnLabelFunc,
    { loading: generateReturnLabelLoader, error: grError },
  ] = useMutation(CREATE_RETURN_LABEL, {
    variables: {
      fedexServiceUUID: fedexService,
      addressUUID: adminChosenAddress?.uuid
        ? adminChosenAddress?.uuid
        : addressUUID,
      transactionUUID: transactionUUID,
    },
    onCompleted: () => {
      dispatch(triggerSuccess('Label successfully generated'));
      fetchTransaction();
    },
  });

  const declineMedallion = async (medallionId: string) => {
    if (!medallionId) return;
    setLoadingProcess(true)
    try {
      if (transactionStatus === 'COMPLETED') {
        await denyApprovedMedallionsFunc({
          variables: {
            medallionUUIDs: [medallionId],
            transactionUUID: transactionUUID
          }
        });
        const medallions = await fetchMedallions();
        if (medallions?.data?.listMedallions?.items?.filter((medallions: any) => medallions?.status !== "DENIED").length === 0) {
          window.location.reload();
        } else {
          fetchTransaction();
        }
      } else {
        await changeMedallionsStatusFunc({
          variables: {
            status: 'DENIED',
            medallionUUIDs: [medallionId],
            transactionUUID: transactionUUID,
          }
        });
        const medallions = await fetchMedallions();
        if (medallions?.data?.listMedallions?.items?.filter((medallions: any) => medallions?.status !== "DENIED").length === 0) {
          window.location.reload();
        } else {
          fetchTransaction();
        }
      }
    }
    catch (err) { }
  };

  React.useEffect(() => {
    if (grError?.message) {
      dispatch(triggerErr(grError?.message));
    }
    if (cmsError?.message) {
      dispatch(triggerErr(cmsError?.message));
    }
    if (gfsError?.message) {
      dispatch(triggerErr(gfsError?.message));
    }
    if (gtError?.message) {
      dispatch(triggerErr(gtError?.message));
    }
    if (grrError?.message) {
      dispatch(triggerErr(grrError?.message));
    }
    if (guError?.message) {
      dispatch(triggerErr(guError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    grError,
    cmsError,
    gfsError,
    gtError,
    grrError,
    guError
  ]);

  React.useEffect(() => {
    if (denyApprovedMedallionsError?.message) {
      dispatch(triggerErr(denyApprovedMedallionsError?.message));
    }
  }, [denyApprovedMedallionsError])

  React.useEffect(() => {
    if (medallionAction.status === '') return;
    setLoadingProcess(true);
    try {
      runChangeMedallionsStatusFunc();
    } catch (err: any) {
      dispatch(triggerErr(err?.message));
    }
    setLoadingProcess(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medallionAction]);

  const runChangeMedallionsStatusFunc = async () => {
    await changeMedallionsStatusFunc();
    const medallions = await fetchMedallions();
    setMedallionsChecked([]);
    if (medallions?.data?.listMedallions?.items?.length === 0) {
      window.location.reload();
    } else {
      fetchTransaction();
    }
  }

  const onActionSelected = (action: string, medallionId: string[]) => {
    if (transactionStatus !== "REVIEWING") return;
    setMedallionAction({ status: action, uuid: medallionId });
  };

  const generateReturnShippingLabel = async () => {
    if (fedexService === '' || transactionUUID === '' || getReturnRateLoader || generateReturnLabelLoader) {
      return;
    }
    await generateReturnLabelFunc();
  };

  const onMedallionChecked = (uuid: any) => {
    const isMedallionChecked = medallionsChecked.includes(uuid);

    let medallionsUpdated = [...medallionsChecked];

    if (isMedallionChecked) {
      medallionsUpdated = medallionsUpdated.filter(id => id !== uuid);
    } else {
      medallionsUpdated.push(uuid);
    }

    setMedallionsChecked(medallionsUpdated);
  };

  const getPendingMedallionsTableBody = () => {

    return transactionMedallions?.map((m: any, i: number) => {

      return <tr key={i}>
        <td>
          <Checkbox {...label} checked={medallionsChecked.includes(m?.uuid)} onChange={() => onMedallionChecked(m?.uuid)} />
        </td>
        <td className="medallions__table-td">
          <span className="medallions__td-body">{certificate?.symbol}</span>
        </td>
        <td className="medallions__table-td">
          <span className="medallions__td-body">$ {certificate?.price}</span>
        </td>
        <td className="medallions__table-td">
          <span className="medallions__td-body">{certificate?.shares}</span>
        </td>
        <td className="medallions__table-td">
          <span className="medallions__td-body">$ {m?.marketValue}</span>
        </td>
        <td className="medallions__table-td">
          <span className="transaction__transaction-id">
            {normelizeStatus(m?.status)}
          </span>
        </td>
        <td className="medallions__table-td">
          <>
            {m.status !== "APPROVED" && <button
              className={`medallions__btn-approve ${medallionsChecked.includes(m?.uuid) && 'medallions__btn-disabled'}`}
              disabled={medallionsChecked.includes(m?.uuid)}
              onClick={() => onActionSelected('APPROVED', [m.uuid])}
            >
              Approve
            </button>}
            {m.status !== "DENIED" && <button
              className={`medallions__btn-decline ${medallionsChecked.includes(m?.uuid) && 'medallions__btn-disabled'}`}
              disabled={medallionsChecked.includes(m?.uuid)}
              onClick={() => onActionSelected('DENIED', [m.uuid])}
            >
              Decline
            </button>}
          </>
        </td>
      </tr >
    });
  };

  const getCompletedMedallionsTableBody = () => {
    return transactionMedallions?.map((m: any, i: number) => (
      <tr key={i}>
        <td className="medallions__table-td">
          <span className="medallions__td-body">{certificate?.symbol}</span>
        </td>
        <td className="medallions__table-td">
          <span className="medallions__td-body">$ {certificate?.price}</span>
        </td>
        <td className="medallions__table-td">
          <span className="medallions__td-body">{certificate?.shares}</span>
        </td>
        <td className="medallions__table-td">
          <span className="medallions__td-body">$ {m?.marketValue}</span>
        </td>
        <td className="medallions__table-td">
          <span className="medallions__td-body">$ 149</span>
        </td>
        <td className="medallions__table-td">
          <span className="transaction__transaction-id">
            {normelizeStatus(m?.status)}
          </span>
        </td>
        {m.status === 'APPROVED' &&
          <td>
            <button
              className="medallions__btn-decline"
              onClick={() => declineMedallion(m.uuid)}
            >
              Decline
            </button>
          </td>
        }
      </tr>
    ));
  };

  const onCertificateClicked = (certificate: any) => {
    setCertificate(certificate)
    setCertificateUUID(certificate.uuid)
    setPage(1)
  };

  const onTableButtonsClicked = (action: ButtonActionType) => {
    let pageNumber = 0;
    if (action === ButtonActionType.BACK) {
      pageNumber = page - 1;
    }
    if (action === ButtonActionType.NEXT) {
      pageNumber = page + 1;
    }
    setPage(pageNumber);
  };

  return (
    <div className="medallions">
      {loadingProcess ? (
        <Loader />
      ) : (
        <>
          {certificateUUID ? <>
            <div style={{
              display: "flex", justifyContent: "space-between", alignItems: "flex-start", height: 100, fontFamily: "Cerebri Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 15,
              maxWidth: 1200
            }}>
              <div onClick={() => setCertificateUUID(null)}
                style={{
                  display: "flex",
                  gap: 15,
                  alignItems: "center",
                  cursor: "pointer"
                }}>
                <ArrowBackIcon />
                <span>Go to certificates list</span>
              </div>
              <div>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="false"
                  name="radio-buttons-group"
                  value={completed}
                  style={{ display: "flex", gap: 15, flexDirection: "row" }}
                >
                  <FormControlLabel value="false" control={<Radio />} label={`Pending medallions (${calculatePendingMedallions(transactionCertificates, certificate?.uuid)})`} onChange={() => setCompleted(false)} />
                  <FormControlLabel value="true" control={<Radio />} label={`Completed medallions (${calculateCompletedMedallions(transactionCertificates, certificate?.uuid)})`} onChange={() => setCompleted(true)} />
                </RadioGroup>
              </div>
            </div>
            {denyApprovedMedallionsLoader || fetchMedallionsLoader || changeMStatusLoader ? <div style={{ minHeight: 48 }}><Loader /></div> : <> {transactionStatus === "PENDING" || transactionStatus === "REVIEWING" || transactionStatus === "COMPLETED" ? <>
              {!!transactionMedallions?.length && transactionStatus !== "COMPLETED" ? (
                <div className="medallions__header">
                  <span className="medallions__header--title">
                    {!completed ? "Pending eMedallions" : "Completed eMedallions"}
                  </span>
                  {!completed && <div className="medallions__header--buttons">
                    <button
                      disabled={!medallionsChecked?.length}
                      className={`medallions__btn-approve ${!medallionsChecked?.length && 'medallions__btn-disabled'}`}
                      onClick={() => onActionSelected('APPROVED', medallionsChecked)}
                    >
                      Approve Checked
                    </button>
                    <button
                      disabled={!medallionsChecked?.length}
                      className={`medallions__btn-decline ${!medallionsChecked?.length && 'medallions__btn-disabled'}`}
                      onClick={() => onActionSelected('DENIED', medallionsChecked)}
                    >
                      Decline Checked
                    </button>
                    <button
                      className="medallions__btn-include"
                      onClick={() => {
                        setMedallionsChecked([...transactionMedallions?.map((medallion: any) => medallion?.uuid)])
                      }}
                    >
                      Select all
                    </button>
                  </div>}
                </div>
              ) : ""}
              <div style={{ minHeight: 48 }}>
                {transactionMedallions?.length ? <Table
                  titles={completed ? completedTableMedallionsTitles : tableMedallionsTitles}
                  onButtonClicked={onTableButtonsClicked}
                  isNextPage={isNextPage}
                  isBackPage={isBackPage}>
                  {completed ? getCompletedMedallionsTableBody() : getPendingMedallionsTableBody()}
                </Table> : <span style={{
                  display: "flex", justifyContent: "center", fontFamily: "Cerebri Sans",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 15
                }}>No data available</span>}
              </div>
            </> : <h4 style={{
              display: "flex",
              justifyContent: "center",
              color: "#353535"
            }}>
              This transaction is with status {transactionStatus}
            </h4>}</>}

          </> : <Certificates certificates={transactionCertificates} onCertificateClicked={onCertificateClicked} />}

          <div className="medallions__generate-box">
            <span className="medallions__generate-box--title">
              Return Options
            </span>
            <div className="medallions__generate-box--select">
              <FormControl
                style={{ maxWidth: 450, width: '100%', marginTop: 24 }}
              >
                <InputLabel id="demo-simple-select-label">
                  Fedex Service
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={fedexService}
                  label="Age"
                  onChange={handleChange}
                >
                  {getFedexServiceData?.getFedexServices.map(
                    (v: any) => (
                      <MenuItem value={v?.uuid} key={v?.uuid}>
                        {v?.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <div
                style={{
                  maxWidth: 135,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span className="medallions__generate-box--return-title">
                  Return Cost
                </span>
                <span className="medallions__generate-box--return-price">
                  $ {getReturnRateLoader ? <Loader size={20} /> : rate}
                </span>
              </div>
            </div>
            <div style={{ flex: 1, marginTop: 24 }}>
              <button
                disabled={
                  getReturnRateLoader ||
                  fedexService.trim() === ''
                }
                className="medallions__generate-box--btn"
                onClick={() => {
                  generateReturnShippingLabel();
                }}
              >
                {generateReturnLabelLoader ? (
                  <Loader size={30} />
                ) : (
                  'Generate Return Fedex Label'
                )}
              </button>
            </div>
          </div>

          <label
            className="transaction-details__green-label pointer"
            style={{ marginTop: 24 }}
            onClick={() => {
              navigate(`/fedex-labels/${transactionUUID}`, { replace: true });
            }}
          >
            Fedex Labels
          </label>
        </>
      )
      }
    </div >
  );
};

export default Medallions;
