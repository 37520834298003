import { gql } from '@apollo/client';

export const GET_SURVEYS = gql`
  query listSurveys($page: Float) {
    listSurveys(limit: 10, page: $page) {
      items {
        uuid
        createdAt
        reasons
        about
        comments
        rate
        user {
          uuid
          email
          firstName
          lastName
          isSurveyAnswered
        }
      }
      total
      hasMore
    }
  }
`;
