import { gql } from '@apollo/client';

export const GENERATE_CSV_REPORT = gql`
  query generateCSVReport($dates: DateRange, $reportUUID: String!) {
    generateCSVReport(
      reportUUID: $reportUUID
      dates: $dates
    )
  }
`;
