import { gql } from '@apollo/client';

export const LIST_REPORTS = gql`
  query {
    listReports {
      uuid
      name
    }
  }
`;
