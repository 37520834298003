import { LazyQueryExecFunction, OperationVariables, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import axios from "axios";

import { ADD_DOCUMENT, GET_DOC_UPLOAD_URL, NOTIFY_DOC_UPLOADED } from '../../graphql/mutations/add-files.mutations';
import Loader from '../loader/loader.component';
import { GET_TRANSACTION } from '../../graphql/queries/get-transaction.query';
import { triggerErr, triggerSuccess } from '../../redux/slices/error-handling/error-handling-slice';
import { useDispatch } from 'react-redux';

type UploadFilesType = {
    transactionUUID: string;
    getTransaction: LazyQueryExecFunction<any, OperationVariables>;
    setTransaction: React.Dispatch<any>;
}

const UploadFiles: React.FC<UploadFilesType> = ({ transactionUUID, getTransaction, setTransaction }) => {

    const [file, setFile] = useState<any>(null);
    const [documentToUpload, setDocumentToUpload] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const [
        addOtherDocsFunc,
        { loading: addOtherDocsLoader, error: addOtherDocsError },
    ] = useMutation(ADD_DOCUMENT, {
        variables: {
            transactionUUID: transactionUUID,
            name: file?.name,
        },
    });

    const [
        getDocUploadUrlFunc,
        { loading: getDocUploadUrlLoader, error: getDocError },
    ] = useMutation(GET_DOC_UPLOAD_URL, {
        variables: {
            documentUUID: documentToUpload?.id,
            transactionUUID: transactionUUID,
        },
        onCompleted: (data: any) =>
            setFileForUpload(documentToUpload?.file, data?.generateDocumentUploadURL),
    });

    const [
        notifyDocUploadedFunc,
        { loading: notifyDocUploadedLoader, error: notifyError },
    ] = useMutation(NOTIFY_DOC_UPLOADED, {
        variables: {
            documentUUID: documentToUpload?.id,
            transactionUUID: transactionUUID,
        },
        refetchQueries: [
            {
                query: GET_TRANSACTION,
                variables: { transactionUUID: transactionUUID },
            },
        ],
        onCompleted: (d) => {
            setIsLoading(false);
            if (d?.notifyDocumentUploaded?.status === "UPLOADED") {
                dispatch(triggerSuccess('File Uploaded! Please refresh the page!'));
                window.location.reload();
            } else {
                notifyDocUploadedFunc();
            }
        },
    });

    const setFileForUpload = async (file: File, url: string) => {
        if (!file) return;
        const formData = new FormData();

        // Update the formData object
        formData!.append(file?.name, file, file?.name);

        axios({
            method: "put",
            url,
            data: file,
            headers: { "Content-Type": file?.type ?? `application/pdf` },
        })
            .then(async (d) => {
                if (d?.status === 200) {
                    try {
                        await notifyDocUploadedFunc();
                    } catch (err) {
                        console.error(err);
                    }
                }
            })
            .catch((error: any) => {
                setIsLoading(false);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.error(error.response.data);
                    dispatch(triggerErr(error.response.data));
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.error(error.request);
                    dispatch(triggerErr(error.request));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error("Error", error.message);
                    dispatch(triggerErr(error.message));
                }
            });
    };

    const onFileChange = (event: any) => {
        const fileSize = event.target.files[0].size / 1024;
        if (fileSize / 1000 <= 5) {
            setFile(event.target.files[0]);
        } else {
            alert("It is not possible to upload files larger than 5MB");
        }
        event.target.value = null;
    };

    const onUploadFile = async (d: string) => {
        document.getElementById(d)?.click();
    };

    const onAddSupportingDoc = () => {
        onUploadFile("file_input_id");
    };

    React.useEffect(() => {
        if (file === null || !file || !file?.name) return;

        let isCancelled = false;
        setIsLoading(true);

        addOtherDocsFunc()
            .then((d: any) => {
                if (!isCancelled && d?.data?.addDocument?.uuid) {
                    let newObjRef = {
                        id: d?.data?.addDocument?.uuid,
                        file: file,
                    };
                    setDocumentToUpload(newObjRef);
                } else {
                    alert("Something went wring, please try again!");
                }
            })
            .catch((err: any) => {
                dispatch(triggerErr(err.message));
                setIsLoading(false);
            });

        return () => {
            isCancelled = true;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    React.useEffect(() => {
        if (!documentToUpload) return;

        try {
            getDocUploadUrlFunc();
        } catch (err: any) {
            dispatch(triggerErr(err.message));
        }
    }, [documentToUpload]);

    React.useEffect(() => {
        if (addOtherDocsError?.message) {
            dispatch(triggerErr(addOtherDocsError?.message));
        }
        if (getDocError?.message) {
            dispatch(triggerErr(getDocError?.message));
        }
        if (notifyError?.message) {
            dispatch(triggerErr(notifyError?.message));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addOtherDocsError, getDocError, notifyError]);

    return (
        <>
            <input
                type="file"
                accept="application/pdf"
                id={"file_input_id"}
                style={{ display: "none" }}
                onChange={(e) => onFileChange(e)}
            />
            {addOtherDocsLoader || getDocUploadUrlLoader || notifyDocUploadedLoader || isLoading ? (
                <Loader size={20} />
            ) : (
                <button
                    style={{ width: 350, marginRight: 25, marginBottom: 35 }}
                    onClick={onAddSupportingDoc}
                >
                    Add Supporting Document
                </button>
            )}</>)
}

export default UploadFiles;