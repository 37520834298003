import { gql } from '@apollo/client';

export const CREATE_RETURN_LABEL = gql`
  mutation createReturnShipping(
    $fedexServiceUUID: String!
    $addressUUID: String!
    $transactionUUID: String!
  ) {
    createReturnShipping(
      transactionUUID: $transactionUUID
      addressUUID: $addressUUID
      fedexServiceUUID: $fedexServiceUUID
    ) {
      uuid
      from {
        addressLine1
        addressLine2
        city
        state
        zip
        country
        type
      }
      to {
        addressLine1
        addressLine2
        city
        state
        zip
        country
        type
      }
      type
      provider
      costInCents
    }
  }
`;
