import React, { useEffect, useRef, useState } from 'react';
import Filters from '../filters/filters.component';
import Navigator from '../navigator/navigator.component';
import Table from '../table/table.component';
import TransactionDetails from '../transaction-details/transaction-details.component';
import {
  getMedallionsLength,
  isFileUploaded,
  navigatorDetails,
  tableTransactionDetailsTitles,
  transactionDetailsFilters,
  transactionReadyForReview,
} from './transaction.helper';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import CloseIcon from '@mui/icons-material/Close';
import './transaction.scss';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_TRANSACTION } from '../../graphql/queries/get-transaction.query';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../loader/loader.component';
import Notes from '../notes/notes.component';
import ShippingInfo from '../shipping-info/shipping-info.component';
import PaymentRefund from '../payment-refund/payment-refund.component';
import Medallions from '../medallions/medallions.component';
import { REVIEW_TRANSACTION } from '../../graphql/mutations/review-transaction.mutation';
import { useDispatch } from 'react-redux';
import { GET_FILE_URL } from '../../graphql/queries/get-file-url.query';
import TransactionVerifications from '../transaction-verifications/transaction-verifications.component';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import { GET_ME } from '../../graphql/queries/get-me.query';
import { userLogin, userLogout } from '../../redux/slices/user/user-slice';
import UploadFiles from '../upload-files/upload-files.component';

const Transaction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Handle click outside the Iframe element
  const specificElementRef: any = useRef(null);
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (specificElementRef.current && !specificElementRef.current.contains(event.target)) {
        setShowViewer(false);
        setDocumentUUIDHover('')
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [specificElementRef]);

  const [getMe, { loading: getMeLoader }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.getMe?.email) {
        dispatch(userLogin(data?.getMe));
        return;
      } else {
        dispatch(userLogout());
        navigate('/login', { replace: true });
      }
    },
  });

  React.useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [transactionUUID, setTransactionUUID] = useState<any>('');
  const [documentUUID, setDocumentUUID] = useState<any>('');
  const [documentUUIDHover, setDocumentUUIDHover] = useState<any>('');
  const [readyForFileReq, setReadyForFileReq] = useState<any>(false);
  const [transaction, setTransaction] = useState<any>({});
  const [transactionComponent, setTransactionComponent] =
    useState<string>('Medallions');
  const [showViewer, setShowViewer] = useState(false);
  const [url, setUrl] = useState('');

  const location = useLocation();

  React.useEffect(() => {
    let arr = location.pathname.split('/');
    const uuid = arr[arr?.length - 1];
    setTransactionUUID(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [reviewTransactionFunc, { error: rtError }] = useMutation(
    REVIEW_TRANSACTION,
    {
      variables: {
        transactionUUID: transactionUUID,
      },
      onCompleted: () => {
        try {
          getTransaction({
            variables: {
              transactionUUID: transactionUUID,
            },
            fetchPolicy: 'no-cache',
            onCompleted: async (data: any) => {
              setTransaction(data?.getTransaction);
            },
          });
        } catch (err) { }
      }
    }
  );

  React.useEffect(() => {
    if (transactionUUID === '') return;
    try {
      getTransaction({
        variables: {
          transactionUUID: transactionUUID,
        },
        fetchPolicy: 'no-cache',
        onCompleted: async (data: any) => {
          setTransaction(data?.getTransaction);

          if (!transactionReadyForReview(data?.getTransaction?.status)) {
            await reviewTransactionFunc();
          }
        },
      });
    } catch (err) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionUUID]);

  const [getTransaction, { loading: getTransactionLoader, error: gtError }] =
    useLazyQuery(GET_TRANSACTION);

  const [getFileUrl, { loading: getFileUrlLoader, error: gfuError }] =
    useLazyQuery(GET_FILE_URL, {
      variables: {
        transactionUUID: transactionUUID,
        documentUUID: documentUUID,
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.getDocumentURL) {
          window.open(data?.getDocumentURL, '_blank');
          setDocumentUUID('');
        }
      },
    });

  const [getFileUrlHover, { loading: getFileUrlHoverLoader, error: getFileUrlHoverError }] = useLazyQuery(GET_FILE_URL, {
    variables: {
      transactionUUID: transactionUUID,
      documentUUID: documentUUIDHover,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setUrl(data?.getDocumentURL);
      setDocumentUUIDHover('');
    },
  });

  React.useEffect(() => {
    if (gfuError?.message && documentUUID !== '') {
      dispatch(triggerErr(gfuError?.message));
    }
    if (gtError?.message) {
      dispatch(triggerErr(gtError?.message));
    }
    if (rtError?.message) {
      dispatch(triggerErr(rtError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gfuError, gtError, rtError]);

  const onFilterClicked = (e: any) => {
    setTransactionComponent(e);
  };

  React.useEffect(() => {
    if (transactionUUID === '' || documentUUID === '') return;
    setReadyForFileReq(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentUUID]);

  React.useEffect(() => {
    if (transactionUUID === '' || documentUUIDHover === '') return;
    getFileUrlHover();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentUUIDHover]);

  React.useEffect(() => {
    if (transactionUUID === '' || documentUUID === '' || !readyForFileReq)
      return;
    getFileUrl();
    setReadyForFileReq(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyForFileReq]);

  const tableBody = transaction?.documents?.map((d: any, i: number) => (
    <tr key={i}>
      <td className="transaction__body-td" style={{ width: 350 }}>
        <span className="transaction__transaction-id">{d?.name}</span>
      </td>
      <td style={{ width: 150 }}>
        {isFileUploaded(d) ? (
          <span
            className="transaction__body-td"
          >
            {getFileUrlLoader && documentUUID === d?.uuid ? (
              <Loader size={20} />
            ) : (
              <FileOpenIcon
                className='pointer'
                onClick={() => {
                  setDocumentUUID(d?.uuid);
                }}
                onMouseOver={() => {
                  if (showViewer || !d?.uuid) return
                  setShowViewer(true);
                  setDocumentUUIDHover(d?.uuid)
                }}
              />
            )}
          </span>
        ) : (
          '-'
        )}
      </td>
      <td style={{ width: 150 }}>
        <span className="transaction__body-td">
          {d?.status.charAt(0).toUpperCase() + d?.status.slice(1).toLowerCase()}
        </span>
      </td>
    </tr>
  ));

  const filesComponent = (<>
    <UploadFiles transactionUUID={transactionUUID} getTransaction={getTransaction} setTransaction={setTransaction} />
    {transaction?.documents?.length ? (
      <Table titles={tableTransactionDetailsTitles} displayButtons={false}>
        {tableBody}
      </Table>
    ) : (
      <h4>No files available</h4>
    )
    }
  </>);

  const transactionComponents: { [key: string]: any } = {
    Medallions: <Medallions />,
    Files: filesComponent,
    Notes: <Notes />,
    'Shipping Information': <ShippingInfo />,
    'Payment / Refund': <PaymentRefund />,
    Verifications: (
      <TransactionVerifications transactionUUID={transactionUUID} />
    ),
  };

  return (
    <>
      {getTransactionLoader || getMeLoader ? (
        <div className="transaction__loader">
          <Loader />
        </div>
      ) : (
        <div className="transaction right-panel-body">
          <Navigator tabs={navigatorDetails} />
          <TransactionDetails transaction={transaction} />
          <hr className="hr transaction__hr" />
          <Filters
            filters={transactionDetailsFilters}
            onFilterClicked={onFilterClicked}
            amount={[
              transaction?.certificates?.reduce((acc: number, certificate: any) => { acc += certificate?.medallionsCount; return acc; }, 0),
              transaction?.documents?.length || 0,
              transaction?.notes?.length || 0,
            ]}
          />
          <div style={{ marginTop: 48, minHeight: 300 }}>
            {transactionComponents[transactionComponent]}
          </div>
        </div>
      )}
      {showViewer && <div className='url-div'>
        <div style={{ display: "flex", justifyContent: "flex-start", cursor: "pointer" }}>
          <CloseIcon onClick={() => {
            setShowViewer(false);
            setDocumentUUIDHover('')
          }} />
        </div>
        {getFileUrlHoverLoader ? <div><Loader /></div> :
          <iframe ref={specificElementRef} src={url} title={url} style={{
            height: "100%",
            width: "100%"
          }}></iframe>}
      </div>
      }
    </>
  );
};

export default Transaction;
