import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { GET_ME } from '../../graphql/queries/get-me.query';
import { GET_RETURN_FEDEX_LABEL_URL } from '../../graphql/queries/get-return-fedex-label-url.query';
import { LIST_RETURN_SHIPPINGS } from '../../graphql/queries/list-return-shippings.query';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import { userLogin, userLogout } from '../../redux/slices/user/user-slice';
import Loader from '../loader/loader.component';
import Navigator, { TabType } from '../navigator/navigator.component';
import Table, { ButtonActionType } from '../table/table.component';
import { fedexLabelsTableTitles } from './fedex-labels.helper';
import './fedex-labels.scss';
import FedexLogo from '../fedex-logo/fedex-logo';

const FedexLabels = () => {
  const navigate = useNavigate();

  const [getMe, { loading: getMeLoader }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.getMe?.email) {
        dispatch(userLogin(data?.getMe));
        return;
      } else {
        dispatch(userLogout());
        navigate('/login', { replace: true });
      }
    },
  });

  React.useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [transactionUUID, setTransactionUUID] = useState<any>('');
  const [listShippings, setListShippings] = useState([]);
  const [returnShippingUUID, setReturnShippingUUID] = useState('');
  const [page, setPage] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);
  const [isBackPage, setIsBackPage] = useState(false);
  const dispatch = useDispatch();

  //actiate pagination (table buttons)
  const onTableButtonsClicked = (action: ButtonActionType) => {
    let pageNumber = 0;
    if (action === ButtonActionType.BACK) {
      pageNumber = page - 1;
    }
    if (action === ButtonActionType.NEXT) {
      pageNumber = page + 1;
    }
    setPage(pageNumber);
  };

  React.useEffect(() => {
    if (returnShippingUUID === '') return;
    getFedexLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnShippingUUID]);

  const location = useLocation();

  React.useEffect(() => {
    let arr = location.pathname.split('/');
    const uuid = arr[arr?.length - 1];
    setTransactionUUID(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    if (transactionUUID === '') return;
    if (page === 0) return;
    listReturnShippingsFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionUUID, page]);

  const [
    listReturnShippingsFunc,
    { loading: listReturnShippingsLoader, error: lrsError },
  ] = useLazyQuery(LIST_RETURN_SHIPPINGS, {
    variables: {
      transactionUUID: transactionUUID,
      page: page,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setListShippings(data?.listReturnShipings?.items);
      setIsNextPage(data?.listReturnShipings?.hasMore);
      setIsBackPage(page > 1);
    },
  });

  const [getFedexLabel, { loading: getFedexLabelLoader, error: gflError }] =
    useLazyQuery(GET_RETURN_FEDEX_LABEL_URL, {
      variables: {
        returnShippingUUID: returnShippingUUID,
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        if (data?.getReturnFedexLabelUrl) {
          window.open(data?.getReturnFedexLabelUrl, '_blank');
        }
      },
    });

  React.useEffect(() => {
    if (gflError?.message) {
      dispatch(triggerErr(gflError?.message));
    }
    if (lrsError?.message) {
      dispatch(triggerErr(lrsError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gflError, lrsError]);

  const discountsTableBody = listShippings?.map((ls: any) => (
    <tr className="all-discounts">
      <td>
        <p
          className="fedex-labels__green-label"
          onClick={() => {
            setReturnShippingUUID(ls?.uuid);
          }}
        >
          {getFedexLabelLoader && ls?.uuid === returnShippingUUID ? (
            <Loader />
          ) : (
            'Shipping Label'
          )}
        </p>
      </td>
      <td>
        <p>{ls?.from?.addressLine1}</p>
        <p>{ls?.from?.state} </p>
        <p>{ls?.from?.city} </p>
        <p>{ls?.from?.zip}</p>
      </td>
      <td>
        <p>{ls?.to?.addressLine1}</p>
        <p>{ls?.to?.state} </p>
        <p>{ls?.to?.city} </p>
        <p>{ls?.to?.zip}</p>
      </td>
      <td>
        {ls?.status === "PAID" ? <td>
          <FedexLogo />
          <CheckIcon color='success' />
        </td> :
          <p>{ls?.status}</p>
        }
      </td>
    </tr>
  ));

  const fedexNavigator: TabType[] = [
    {
      name: 'Transaction Details',
      navigateTo: `/transaction/${transactionUUID}`,
      isLeaf: true,
    },
    { name: 'Fedex labels', navigateTo: '', isLeaf: false },
  ];

  return (
    <div className="fedex-labels right-panel-body">
      <Navigator tabs={fedexNavigator} />
      <span className="fedex-labels__title">Fedex lables</span>
      {listReturnShippingsLoader || getMeLoader ? (
        <Loader />
      ) : (
        <div style={{ marginTop: 48 }}>
          <Table
            titles={fedexLabelsTableTitles}
            onButtonClicked={onTableButtonsClicked}
            isNextPage={isNextPage}
            isBackPage={isBackPage}
          >
            {discountsTableBody}
          </Table>
        </div>
      )}
    </div>
  );
};

export default FedexLabels;
