import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { normelizeUrl, sidenavTabs } from './side-nav.helper';

import './side-nav.scss';

const Sidenav = () => {
  const [clicked, setClicked] = useState(0);
  const [currentPage, setCurrentPage] = useState('');
  const [isLoginPage, setIsLoginPage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    let arr = location.pathname.split('/');
    const currentPage = arr[1];
    setIsLoginPage(currentPage === 'login');
    setCurrentPage(currentPage);
    setClicked(sidenavTabs.findIndex((e: string) => e === currentPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {isLoginPage ? (
        ''
      ) : (
        <div className="side-nav__container">
          <div className="side-nav">
            {sidenavTabs.map((tab: string, i: number) => (
              <div
                key={i}
                className={`side-nav__tab ${
                  clicked === i || currentPage === normelizeUrl(tab)
                    ? 'side-nav__clicked'
                    : ''
                }`}
                onClick={() => {
                  setClicked(i);
                  navigate(`/${normelizeUrl(tab)}`, { replace: true });
                }}
              >
                {tab}
              </div>
            ))}
          </div>
          <div style={{ display: 'flex' }}>
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default Sidenav;
