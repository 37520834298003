import React, { useEffect, useState } from 'react';
import SearchIcon from '../search-icon/search-icon.component';

import './search-box.scss';

type searchBoxType = {
  placeHolder: string;
  onInputChange: (e: any) => void;
  resetInput?: number;
};

const SearchBox: React.FC<searchBoxType> = ({ placeHolder, onInputChange, resetInput }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (resetInput === 0) return;
    setValue("");
  }, [resetInput]);

  return (
    <div className="search-box__container">
      <input
        autoFocus
        className="search-box"
        placeholder={placeHolder}
        onChange={(e) => {
          setValue(e?.target?.value);
          onInputChange(e);
        }}
        value={value}
      />
      <div className="search-box__search-icon">
        <SearchIcon />
      </div>
    </div>
  );
};

export default SearchBox;
