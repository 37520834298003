export const discountsFilters = [
  'All',
  'Active',
  'Pending',
  'Disabled',
  'Cancelled',
];

export const discountsTableTitles = [
  'Created Date',
  'Created By',
  'Code',
  'Calc Type',
  'Amount',
  'Account',
  'Max Count',
  'Remaining',
  'Expire',
  'Ticker',
  'Is free coupon',
  'Disable',
];

export const getAmountArr = (d: any) => {
  return [
    undefined,
    getTransactionsByStatus(d, 'Active').length,
    getTransactionsByStatus(d, 'Pending').length,
    getTransactionsByStatus(d, 'Disabled').length,
    getTransactionsByStatus(d, 'Cancelled').length,
  ];
};

export const getTransactionsByStatus = (discounts: any, status: string) => {
  return discounts.filter(function (d: any) {
    return d.status.toLowerCase() === status.toLowerCase();
  });
};
