import { gql } from '@apollo/client';

export const GET_ME = gql`
  query {
    getMe {
      firstName
      lastName
      email
      roles
      paymentMethod
      status
      dayOfBirth
      createdAt
      mobile {
        uuid
        countryCode
        localNumber
        isVerified
      }
      home {
        uuid
        addressLine1
        addressLine2
        zip
        country
        state
      }
      isSurveyAnswered
    }
  }
`;
