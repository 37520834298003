import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DELETE_USER } from '../../graphql/mutations/delete-user.mutation';
import { LOCK_USER } from '../../graphql/mutations/lock-user.mutation';
import { UNLOCK_USER } from '../../graphql/mutations/unlock-user.mutation';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import Loader from '../loader/loader.component';

import './delete-user.scss';

type deleteUserType = {
  isLockButton: boolean;
  user: any;
};

const DeleteUser: React.FC<deleteUserType> = ({ isLockButton, user }) => {
  const [userUUID, setUserUUID] = useState<any>('');
  const dispatch = useDispatch();

  const location = useLocation();

  React.useEffect(() => {
    let arr = location.pathname.split('/');
    const uuid = arr[arr?.length - 1];
    setUserUUID(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [deleteUserFunc, { loading: deleteUserLoader, error: duError }] =
    useMutation(DELETE_USER, {
      variables: {
        userUUID: userUUID,
      },
      onCompleted: () => window.location.reload()
    });

  const [unlockUserFunc, { loading: unlockUserLoader, error: uuError }] =
    useMutation(UNLOCK_USER, {
      variables: {
        userUUID: userUUID,
      },
      onCompleted: () => window.location.reload()
    });

  const [lockUserFunc, { loading: lockUserLoader, error: luError }] =
    useMutation(LOCK_USER, {
      variables: {
        userUUID: userUUID,
      },
      onCompleted: () => window.location.reload()
    });

  React.useEffect(() => {
    if (luError?.message) {
      dispatch(triggerErr(luError?.message));
    }
    if (uuError?.message) {
      dispatch(triggerErr(uuError?.message));
    }
    if (duError?.message) {
      dispatch(triggerErr(duError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [luError, uuError, duError]);

  const lock = async () => await lockUserFunc();
  const unlock = async () => await unlockUserFunc();
  const deleteUser = async () => await deleteUserFunc();

  const openDialog = (action: string, cb: () => void) => {
    if (window.confirm(`Are you sure you want to ${action} ${user?.firstName} ${user.lastName}?`)) {
      cb();
    }
  }

  const lockUserBtn = <button className="delete-user__btn-delete" onClick={() => openDialog('Lock', () => lock())}>
    {lockUserLoader ? <Loader size={20} /> : 'Lock user'}
  </button>;

  const unlockUserBtn = <button className="delete-user__btn-unlock" onClick={() => openDialog('Unlock', () => unlock())}>
    {unlockUserLoader ? <Loader size={20} /> : 'Unlock user'}
  </button>;

  return (
    <>
      {isLockButton ? (
        <div>
          {
            user?.status !== "LOCKED" ?
              <div style={{ display: "flex", flexDirection: "column", gap: 32 }}>
                <span>Block user access to the system</span>
                {lockUserBtn}
              </div> :
              <div style={{ display: "flex", flexDirection: "column", gap: 32 }}>
                <span>Unlock user access to the system</span>
                {unlockUserBtn}
              </div>
          }
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: 32 }}>
          <span>Delete a user from the system</span>
          <button className="delete-user__btn-delete" onClick={deleteUser}>
            {deleteUserLoader ? <Loader size={20} /> : 'Delete user'}
          </button>
        </div>
      )}
    </>
  );
};

export default DeleteUser;
