import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/navbar.component';
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
  HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ApolloProvider } from '@apollo/client/react';
import store from './redux/store';

import Reports from './components/reports/reports.component';
import Sidenav from './components/side-nav/side-nav.component';
import Surveys from './components/surveys/surveys.component';
import Transactions from './components/transactions/transactions.component';
import { apolloURL } from './shared/constants/apollo-url.constant';
import Transaction from './components/transaction/transaction.component';
import Users from './components/users/users.component';
import User from './components/user/user.component';
import AllDiscounts from './components/all-discounts/all-discounts.component';
import CreateDiscount from './components/create-discount/create-discount.component';
import FedexLabels from './components/fedex-labels/fedex-labels.component';
import Login from './components/login/login';
import { userLogout } from './redux/slices/user/user-slice';

const httpLink = new HttpLink({
  uri: apolloURL,
});

// Setup the header for the request
const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  const authorizationTokenHeader = token ? `${token}` : null;
  operation.setContext({
    headers: {
      authorization: authorizationTokenHeader,
      // FOR CORS IF NEEDED
      // 'Access-Control-Allow-Methods': 'OPTIONS,POST',
      // 'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
      // 'Access-Control-Allow-Origin': '*',
    },
  });
  return forward(operation);
});

//After the backend responds, we take the refreshToken from headers if it exists, and save it in the cookie.
const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const refreshToken = headers.get('x-esig-access-token');
      if (refreshToken) {
        localStorage.setItem('token', refreshToken);
      }
    }

    return response;
  });
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (
        message === "Access denied! You don't have permission for this action!"
      ) {
        store.dispatch(userLogout());
        window.location.href = '/login';
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, extensions ${extensions}`
      );
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: from([errorLink, middlewareAuthLink, afterwareLink, httpLink]),
  cache: new InMemoryCache(),
  credentials: 'include',
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Navbar />
      <div
        style={{
          marginTop: 80,
          padding: '0px 56px 48px 56px',
          display: 'flex',
          flexWrap: 'wrap',
          gap: 100,
        }}
      >
        <BrowserRouter>
          <Sidenav />
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="transaction/:id" element={<Transaction />} />
            <Route path="fedex-labels/:id" element={<FedexLabels />} />
            <Route path="users" element={<Users />} />
            <Route path="user/:id" element={<User />} />
            <Route path="discount-codes" element={<AllDiscounts />} />
            <Route path="discount-codes/create" element={<CreateDiscount />} />
            <Route path="surveys" element={<Surveys />} />
            <Route path="reports" element={<Reports />} />
            <Route path="*" element={<Navigate to="login" replace />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ApolloProvider>
  );
}

export default App;
