import React from 'react';
import parser from 'fast-xml-parser';
import ReactJson from 'react-json-view';
import DisplayJSON from '../display-json/display-json';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const parseXML = (xml: string): any => {
  const options = {
    attributeNamePrefix: '',
    attrNodeName: 'attributes',
    textNodeName: 'text',
    ignoreAttributes: false,
    ignoreNameSpace: false,
    parseAttributeValue: true,
  };

  const jsonObj = new parser.XMLParser(options);
  return jsonObj.parse(xml)
};

type userValitionType = {
  validations: any[];
};



const UserValidation: React.FC<userValitionType> = ({ validations }) => {
  const [xmlData, setXmlData] = React.useState<any>([]);

  React.useEffect(() => {
    validations?.map((validation: any) => {
      const jsonObject = parseXML(validation?.requestResponse);

      xmlData.push(jsonObject.response)
      setXmlData(xmlData);
      return validation;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validations]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, border: 'none' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Viewer" {...a11yProps(0)} />
          <Tab label="Raw" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <DisplayJSON validations={validations} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ReactJson src={xmlData} />
      </TabPanel>
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default UserValidation;

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
