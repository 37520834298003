import React from 'react';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

import './table.scss';

export enum ButtonActionType {
  BACK = 'BACK',
  NEXT = 'NEXT',
}

type TableType = {
  titles: string[];
  onButtonClicked?: (action: ButtonActionType) => void;
  isNextPage?: boolean;
  isBackPage?: boolean;
  displayButtons?: boolean;
  children?: React.ReactNode;
};

const Table: React.FC<TableType> = ({
  titles,
  onButtonClicked = () => {},
  isNextPage = false,
  isBackPage = false,
  displayButtons = true,
  children,
  ...props
}) => {
  const onBtnClicked = (action: ButtonActionType) => {
    if (action === ButtonActionType.BACK && !isBackPage) return;
    if (action === ButtonActionType.NEXT && !isNextPage) return;
    onButtonClicked(action);
  };

  return (
    <div className="table" {...props}>
      <table>
        <thead>
          <tr>
            {titles?.map((t: string, i: number) => (
              <th className="table__title" key={i}>
                {t}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
      <div className="table__buttons">
        <ArrowBackIosOutlinedIcon
          color={!isBackPage ? 'disabled' : 'inherit'}
          sx={{ cursor: isBackPage ? 'pointer' : 'default' }}
          onClick={() => onBtnClicked(ButtonActionType.BACK)}
        />
        <ArrowForwardIosOutlinedIcon
          color={!isNextPage ? 'disabled' : 'inherit'}
          sx={{ cursor: isNextPage ? 'pointer' : 'default' }}
          onClick={() => onBtnClicked(ButtonActionType.NEXT)}
        />
      </div>
    </div>
  );
};

export default Table;
