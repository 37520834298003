import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { LIST_USERS } from '../../graphql/queries/list-users.query';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import { useDispatch } from 'react-redux';
import Loader from '../loader/loader.component';

type UsersDropdownType = {
  onUserUUID: (e: any) => void;
};

const UsersDropdown: React.FC<UsersDropdownType> = ({ onUserUUID }) => {
  const [users, setUsers] = useState<any[]>([]);
  const [userUUID, setUserUUID] = useState<string>('');
  const dispatch = useDispatch();

  const { error: guError, loading: guLoader } = useQuery(LIST_USERS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setUsers(data?.listUsers?.items);
    },
  });

  React.useEffect(() => {
    if (guError?.message) {
      dispatch(triggerErr(guError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guError]);

  const onValueChanged = (e: any) => {
    setUserUUID(e.target.value);
    onUserUUID(e);
  };

  return (
    <>
      {guLoader ? (
        <Loader />
      ) : (
        <FormControl>
          <InputLabel id="demo-simple-select-label">Email</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={userUUID}
            label="Type"
            onChange={onValueChanged}
            name="userUUID"
            style={{ width: '100%' }}
          >
            {users.map((u: any, i: number) => (
              <MenuItem value={u?.uuid} key={u?.uuid}>
                {u?.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default UsersDropdown;
