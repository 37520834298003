import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { GET_TRANSACTION } from '../../graphql/queries/get-transaction.query';
import Loader from '../loader/loader.component';

import './payment-refund.scss';
import { REFUND } from '../../graphql/mutations/refund.mutation';
import { useDispatch } from 'react-redux';
import {
  triggerErr,
  triggerSuccess,
} from '../../redux/slices/error-handling/error-handling-slice';
import moment from 'moment';
import { Discount, translateDiscount } from './payment-refund.types';

const PaymentRefund = () => {
  const [transactionUUID, setTransactionUUID] = useState<any>('');
  const [transaction, setTransaction] = useState<any>({});
  const [refundValue, setRefundValue] = useState<any>(null);

  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    let arr = location.pathname.split('/');
    const uuid = arr[arr?.length - 1];
    setTransactionUUID(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    if (transactionUUID === '') return;
    fetchTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionUUID]);

  const fetchTransaction = () => {
    try {
      getTransaction({
        variables: {
          transactionUUID: transactionUUID,
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
          setTransaction(data?.getTransaction)
        },
      });
    } catch (err) { }
  };

  const [getTransaction, { loading: getTransactionLoader, error: gtError }] =
    useLazyQuery(GET_TRANSACTION);

  const [refundFunc, { loading: refundFuncLoader, error: refundError }] =
    useMutation(REFUND, {
      variables: {
        transactionUUID: transactionUUID,
        refundInCents: refundValue * 100,
      },
      onCompleted: () => {
        dispatch(triggerSuccess('Successfully refunded!'));
        setRefundValue('')
        fetchTransaction();
      },
    });

  React.useEffect(() => {
    setRefundValue('')
    if (refundError?.message) {
      dispatch(triggerErr(refundError?.message));
    }
    if (gtError?.message) {
      dispatch(triggerErr(gtError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundError, gtError]);

  const onInputChanged = (e: any) => {
    setRefundValue(e.target.value);
  };

  const refund = async () => {
    if (refundValue <= 0 || !refundValue) return;
    try {
      await refundFunc();
    } catch (err) {
      setRefundValue('')
    }
  };

  const paymentDetails = transaction?.charge?.usaePay?.details;
  const userName =
    transaction?.user?.firstName + ' ' + transaction?.user?.lastName;

  return (
    <>
      {getTransactionLoader ? (
        <Loader />
      ) : (
        <>
          <div className="payment-refund">
            <div>
              <div>
                <label className="payment-refund__label">
                  Total Amount For Invoice
                </label>
                <p className="payment-refund__sub-label">
                  $ {paymentDetails?.UMauthAmount || '---'}
                </p>
              </div>
              <div style={{ marginTop: 24 }}>
                <label className="payment-refund__label">
                  Shipping Amount Total
                </label>
                <p className="payment-refund__sub-label">
                  $ {(transaction?.charge?.totalShippingCostInCents / 100).toFixed(2)}
                </p>
              </div>
              <div style={{ marginTop: 24 }}>
                <label className="payment-refund__label">Total Discount</label>
                <p className="payment-refund__sub-label">
                  $ {(transaction?.charge?.totalDiscountsInCents / 100).toFixed(2)}
                </p>
              </div>
              {transaction?.discounts?.length > 0 &&
                <div style={{ marginTop: 24 }}>
                  <label className="payment-refund__label">Discount codes</label>
                  {transaction?.discounts?.map((discount: Discount) =>
                    <>
                      <p className="payment-refund__sub-label">
                        <b>Code:</b> <span style={{ padding: 5, background: "lightgray" }}>{discount?.code}</span>,
                        <b> Value:</b> {translateDiscount(discount.valueType, discount?.value)}
                      </p>
                    </>
                  )}
                </div>}
              <div style={{ marginTop: 24 }}>
                <label className="payment-refund__label">Total Refunded</label>
                <p className="payment-refund__sub-label">
                  $ {(transaction?.totalRefundsInCents / 100).toFixed(2)}
                </p>
              </div>
            </div>
            <div>
              <div>
                <label className="payment-refund__label">Name</label>
                <p className="payment-refund__sub-label">{userName}</p>
              </div>
              <div style={{ marginTop: 24 }}>
                <label className="payment-refund__label">CC Last 4</label>
                <p className="payment-refund__sub-label">
                  {paymentDetails?.UMmaskedCardNum || '---'}
                </p>
              </div>
              <div style={{ marginTop: 24 }}>
                <label className="payment-refund__label">Auth Code</label>
                <p className="payment-refund__sub-label">
                  {paymentDetails?.UMauthCode || '---'}
                </p>
              </div>
              <div style={{ marginTop: 24 }}>
                <label className="payment-refund__label">Batch</label>
                <p className="payment-refund__sub-label">
                  {paymentDetails?.UMbatch || '---'}
                </p>
              </div>
            </div>
            <div>
              <div>
                <label className="payment-refund__label">Ref Number</label>
                <p className="payment-refund__sub-label">
                  {paymentDetails?.UMrefNum || '---'}
                </p>
              </div>
              {paymentDetails?.UMrefNum && <div>
                <label className="payment-refund__label">Invoice Ref</label>
                <p className="payment-refund__sub-label">
                  {transaction?.charge?.uuid?.substr(0, 10) || '---'}
                </p>
              </div>}
              <div style={{ marginTop: 24 }}>
                <label className="payment-refund__label">Result</label>
                <p className="payment-refund__sub-label">
                  {paymentDetails?.UMresult || '---'}
                </p>
              </div>
              <div style={{ marginTop: 24 }}>
                <label className="payment-refund__label">Status</label>
                <p className="payment-refund__sub-label">
                  {paymentDetails?.UMstatus || '---'}
                </p>
              </div>
              <div style={{ marginTop: 24 }}>
                <label className="payment-refund__label">Date</label>
                <p className="payment-refund__sub-label">
                  {paymentDetails?.UMstatus ?
                    <label className="transaction-details__regular-title">
                      {moment(transaction?.charge?.updatedAt).format('LL')}{' '}
                      {moment(transaction?.charge?.updatedAt).format('LTS')}
                    </label> :
                    '---'}
                </p>
              </div>
            </div>
          </div>
          <div className="payment-refund__refund-box">
            <b className="payment-refund__title">Refund</b>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: 24,
              }}
            >
              <input
                onChange={onInputChanged}
                min="0"
                className="input"
                type={'number'}
                style={{ maxWidth: 230, marginRight: 24 }}
                placeholder="$0"
                value={refundValue}
              />
              <button
                className="payment-refund__btn"
                onClick={() => {
                  refund();
                }}
              >
                {refundFuncLoader ? <Loader size={30} /> : 'Refund'}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PaymentRefund;
