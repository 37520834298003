import * as React from 'react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logo from '../logo/logo.component';

import './navbar.scss';
import WithErrorHandling from '../with-error-handling/with-error-handling.component';
import { DocumentNode } from '@apollo/client';

const itemStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}

const Navbar = () => {
  const isLogin = useSelector((state: any) => state.user.isLogin);
  const user = useSelector((state: any) => state.user.userData);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    handleClose();

    localStorage.clear();
    sessionStorage.clear();
    window.location.replace('/login');
  }

  return (
    <div className="navbar">
      <div className="navbar__logo">
        <Logo />
      </div>
      <div>
        {isLogin ? (
          <>
            <Button
              id="demo-positioned-button"
              aria-controls={open ? 'demo-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <AccountCircleOutlinedIcon
                sx={{
                  width: {
                    md: 40,
                    xs: 30,
                  },
                  height: {
                    md: 40,
                    xs: 30,
                  },
                  color: 'gray',
                }}
              />
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div style={{ width: 350, padding: 10 }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  <div style={itemStyle}>
                    <AccountCircleIcon />
                    {user?.firstName ?? ''} {user?.lastName ?? ''}
                  </div>
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  <div style={itemStyle}>
                    <MailIcon />
                    {user?.email ?? ''}
                  </div>
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  <div style={itemStyle}>
                    <LocalPhoneIcon />
                    {user?.mobile?.countryCode ? '+' + user?.mobile?.countryCode + '-' : ''} {user?.mobile?.localNumber ?? ''}
                  </div>
                </Typography>
              </div>
              <div style={{ padding: 10 }}>
                <button style={{ background: "#28DAB6", width: "100%", color: "#FFF", border: 'none', cursor: 'pointer' }}
                  onClick={() => onLogout()}>Logout</button>
              </div>
            </Menu>
          </>
        ) : (
          ''
        )}
      </div>
    </div >
  );
};

export default WithErrorHandling(Navbar);
function useLazytQuery(GET_ME: DocumentNode, arg1: { fetchPolicy: string; onCompleted: (data: any) => void; }) {
  throw new Error('Function not implemented.');
}

