import React from 'react';

const RightArrow = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.16">
        <path
          d="M8 14L12 10L8 6"
          stroke="#353535"
          strokeWidth="1.5"
          strokeLinecap="square"
        />
      </g>
    </svg>
  );
};

export default RightArrow;
