export interface IInitialAjustedAddressValues {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
}

export interface adminLogs {
  uuid: string;
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
  type: string;
}

export const INITIAL_ADJUSTED_ADDRESS_VALUES = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  name: '',
};

export const INITIAL_ADMIN_CHOSEN_ADDRESS = {
  uuid: '',
  addressLine1: '',
  addressLine2: null,
  city: '',
  state: '',
  zip: '',
  type: '',
  country: '',
};

export const validateAdjustObj = (
  obj: IInitialAjustedAddressValues
): boolean => {
  return (
    obj.addressLine1 !== '' &&
    obj.city !== '' &&
    obj.zip !== '' &&
    obj.state !== ''
  );
};
