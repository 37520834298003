import { gql } from '@apollo/client';

export const REVIEW_TRANSACTION = gql`
  mutation reviewTransaction($transactionUUID: String!) {
    reviewTransaction(transactionUUID: $transactionUUID) {
      uuid
      code
      stockholderType
      format
      securityType
      createdAt
      status
      certificates {
        uuid
        symbol
        medallionsCount
      }
      notes {
        uuid
        content
        createdAt
      }
      transferAgent {
        uuid
        name
      }
      shippings {
        uuid
        from {
          addressLine1
          addressLine2
          state
          zip
        }
        to {
          addressLine1
          addressLine2
          state
          zip
        }
        type
        provider
        createdAt
      }
      discounts {
        code
        valueType
        value
      }
      charge {
        status
        type
        provider
        totalDiscountsInCents
        totalMedallionCostInCents
        totalShippingCostInCents
      }
    }
  }
`;
