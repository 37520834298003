import { gql } from '@apollo/client';

export const GET_ADJUSTED_RETURN_SHIPPING = gql`
  query getAdjustedReturnAddress($transactionUUID: String!) {
    getAdjustedReturnAddress(transactionUUID: $transactionUUID) {
      name
      uuid
      addressLine1
      addressLine2
      city
      state
      zip
      country
      type
      isDefault
    }
  }
`;
