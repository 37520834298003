import { gql } from '@apollo/client';

export const LIST_ADJUSTED_RETURN_ADDRESSES = gql`
  query {
    listAdjustedReturnAddresses {
      name
      uuid
      addressLine1
      addressLine2
      city
      state
      zip
      country
      type
      isDefault
    }
  }
`;
