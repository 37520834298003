import { TabType } from '../navigator/navigator.component';
import { DiscoutRules } from './create-discount.types';

export const discountsNavigatorDetails: TabType[] = [
  { name: 'Discount codes', navigateTo: '/discount-codes', isLeaf: true },
  { name: 'Create', navigateTo: '', isLeaf: false },
];

export const rulesToAdd = (newDiscountObj: any): DiscoutRules[] => {
  const arr = [];
  if (newDiscountObj.expireOn !== '') {
    arr.push(DiscoutRules.EXPIRE_ON);
  }
  if (newDiscountObj.maxCount !== '') {
    arr.push(DiscoutRules.MAX_COUNT);
  }
  if (newDiscountObj.ticker !== '') {
    arr.push(DiscoutRules.TICKER);
  }
  if (newDiscountObj.userUUID !== '') {
    arr.push(DiscoutRules.EMAIL);
  }
  return arr;
};
