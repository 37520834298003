import { gql } from '@apollo/client';

export const CREATE_DISCOUNT = gql`
  mutation createDiscount($input: CreateDiscountInput!) {
    createDiscount(input: $input) {
      uuid
      code
      valueType
      value
      status
      rules
    }
  }
`;

// $code: String!
// $expireOn: DateTime
// $maxCount: Float
// $rules: [DiscountRules!]!
// $ticker: String
// $userUUID: String
// $value: Float!
// $valueType: ValueType!
