import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertColor, Snackbar } from '@mui/material';
import Alert from '../alert/alert.component';
import {
  triggerErr,
  triggerSuccess,
} from '../../redux/slices/error-handling/error-handling-slice';

const WithErrorHandling = (Component: React.ComponentType) =>
  function Comp(props: React.ComponentPropsWithRef<any>) {
    // for error handling
    const isError = useSelector((state: any) => state.errors.error);
    const isSuccess = useSelector((state: any) => state.errors.success);
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>('');
    const [color, setColor] = React.useState<AlertColor>('error');

    React.useEffect(() => {
      if (!isError) return;
      setColor('error');
      openAlert(isError);
    }, [isError]);

    React.useEffect(() => {
      if (!isSuccess) return;
      setColor('success');
      openAlert(isSuccess);
    }, [isSuccess]);

    const openAlert = (error: any) => {
      setError(error);
      setOpen(true);
    };

    const handleClose = (
      event?: React.SyntheticEvent | Event,
      reason?: string
    ) => {
      if (reason === 'clickaway') {
        return;
      }
      dispatch(triggerErr(''));
      dispatch(triggerSuccess(''));
      setOpen(false);
    };
    return (
      <>
        <Component {...props} />
        <Snackbar
          open={open}
          autoHideDuration={10000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      </>
    );
  };

export default WithErrorHandling;
