import React from 'react';
import moment from 'moment';

import './note.scss';
import { useNavigate } from 'react-router-dom';

const imageFile = '/assets/images/person.png';

export interface INote {
  author: {
    uuid: string;
    firstName: string;
    lastName: string;
    email: string;
    profilePicture: string;
  };
  uuid: string;
  content: string;
  createdAt: string;
}

export type NoteType = {
  note: INote;
};

const Note: React.FC<NoteType> = ({ note }) => {
  const navigate = useNavigate();

  return (
    <div className="note">
      <div className="note__img">
        <img src={note?.author?.profilePicture || imageFile} alt="user-img" />
      </div>
      <div className="note__content">
        <label
          className="note__green-label pointer"
          onClick={() => {
            navigate(`/user/${note?.author?.uuid}`, { replace: true });
          }}
        >
          {note?.author?.firstName || '-'} {note?.author?.lastName || '-'}
        </label>
        <br />
        <label className="note__green-label">{note?.author?.email}</label>
        <div className="table__body-transaction-id">
          <span className="note__time">
            {moment(note?.createdAt).format('LL')}{' '}
            {moment(note?.createdAt).format('LTS')}
          </span>
        </div>
        <p className="note__comment">{note.content}</p>
      </div>
    </div>
  );
};

export default Note;
