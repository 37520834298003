export const sidenavTabs = [
  'Transactions',
  'Users',
  'Discount Codes',
  'Surveys',
  'Reports',
];

export const normelizeUrl = (tab: string) => {
  return tab.replaceAll(' ', '-').toLocaleLowerCase();
};
