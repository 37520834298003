import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GET_ME } from '../../graphql/queries/get-me.query';
import { GET_SURVEYS } from '../../graphql/queries/get-surveys.query';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import { userLogin, userLogout } from '../../redux/slices/user/user-slice';
import Loader from '../loader/loader.component';
import PageTitle from '../page-title/page-title.component';
import Table, { ButtonActionType } from '../table/table.component';
import { surveysTableTitles } from './surveys.helper';

import './surveys.scss';

const Surveys = () => {
  const [page, setPage] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);
  const [isBackPage, setIsBackPage] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getMe, { loading: getMeLoader }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.getMe?.email) {
        dispatch(userLogin(data?.getMe));
        return;
      } else {
        dispatch(userLogout());
        navigate('/login', { replace: true });
      }
    },
  });

  React.useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //actiate pagination (table buttons)
  const onTableButtonsClicked = (action: ButtonActionType) => {
    let pageNumber = 0;
    if (action === ButtonActionType.BACK) {
      pageNumber = page - 1;
    }
    if (action === ButtonActionType.NEXT) {
      pageNumber = page + 1;
    }
    setPage(pageNumber);
  };

  React.useEffect(() => {
    if (page === 0) return;
    fetchSurveys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const [surveys, setSurveys] = useState([]);

  const [fetchSurveys, { loading: getSurveysLoading, error: gsError }] =
    useLazyQuery(GET_SURVEYS, {
      variables: {
        page: page,
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        setSurveys(data?.listSurveys?.items);
        setIsNextPage(data?.listSurveys?.hasMore);
        setIsBackPage(page > 1);
      },
    });

  React.useEffect(() => {
    if (gsError?.message) {
      dispatch(triggerErr(gsError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gsError]);

  const surveysTableBody = surveys?.map((s: any) => (
    <tr className="all-discounts">
      <td>
        <p
          className="transaction-details__green-label pointer"
          onClick={() => {
            if (!s?.user?.uuid) return;
            navigate(`/user/${s?.user?.uuid}`);
          }}
        >
          {s?.user?.firstName} {s?.user?.lastName}
        </p>
      </td>
      <td>
        <p>{s?.about}</p>
      </td>
      <td>
        <p>{s?.reasons}</p>
      </td>
      <td>
        <p>{s?.comments}</p>
      </td>
      <td>
        <p>{s?.rate}</p>
      </td>
    </tr>
  ));

  return (
    <div className="surveys right-panel-body">
      <PageTitle />
      {getSurveysLoading || getMeLoader ? (
        <Loader />
      ) : (
        <div style={{ marginTop: 48 }}>
          <Table
            titles={surveysTableTitles}
            onButtonClicked={onTableButtonsClicked}
            isNextPage={isNextPage}
            isBackPage={isBackPage}
          >
            {surveysTableBody}
          </Table>
        </div>
      )}
    </div>
  );
};

export default Surveys;
