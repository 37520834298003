import { gql } from '@apollo/client';

export const ADJUST_RETURN_ADDRESS = gql`
  mutation adjustReturnAddress(
    $transactionUUID: String!
    $addressLine1: String!
    $addressLine2: String
    $city: String!
    $state: String!
    $zip: String!
    $name: String
  ) {
    adjustReturnAddress(
      transactionUUID: $transactionUUID
      input: {
        name: $name
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        state: $state
        zip: $zip
        type: SHIPPING
      }
    ) {
      uuid
      addressLine1
      addressLine2
      city
      state
      zip
      country
      type
      isDefault
    }
  }
`;
