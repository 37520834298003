import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import React, { useState } from 'react';
import { LIST_DISCOUNTS } from '../../graphql/queries/list-discounts.query';
import Filters from '../filters/filters.component';
import PageTitle from '../page-title/page-title.component';
import SearchBox from '../search-box/search-box.component';
import Table, { ButtonActionType } from '../table/table.component';
import {
  discountsFilters,
  discountsTableTitles,
  getAmountArr,
  getTransactionsByStatus,
} from './all-discounts.helper';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import './all-discounts.scss';

import Loader from '../loader/loader.component';
import { DISABLE_DISCOUNT } from '../../graphql/mutations/disable-discount.mutation';
import { ACTIVATE_DISCOUNT } from '../../graphql/mutations/activate-discount.mutation';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import { userLogin, userLogout } from '../../redux/slices/user/user-slice';
import { GET_ME } from '../../graphql/queries/get-me.query';

const AllDiscounts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getMe, { loading: getMeLoader }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.getMe?.email) {
        dispatch(userLogin(data?.getMe));
        return;
      } else {
        dispatch(userLogout());
        navigate('/login', { replace: true });
      }
    },
  });

  React.useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [discounts, setDiscounts] = useState([]);
  const [discountsToDisplay, setDiscountsToDisplay] = useState([]);
  const [discountUUID, setDiscountUUID] = useState('');
  const [status, setStatus] = useState('');
  const [page, setPage] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);
  const [isBackPage, setIsBackPage] = useState(false);

  //actiate pagination (table buttons)
  const onTableButtonsClicked = (action: ButtonActionType) => {
    let pageNumber = 0;
    if (action === ButtonActionType.BACK) {
      pageNumber = page - 1;
    }
    if (action === ButtonActionType.NEXT) {
      pageNumber = page + 1;
    }
    setPage(pageNumber);
  };

  React.useEffect(() => {
    if (page === 0) return;
    fetchDiscounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  React.useEffect(() => {
    if (discountUUID === '' || status === '') return;

    if (status === 'ACTIVE') {
      deisableDiscountFunc();
    }

    if (status === 'DISABLED' || status === 'PENDING') {
      activateDiscountFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountUUID, status]);

  const [fetchDiscounts, { loading: getDiscountsLoader, error: fdError }] =
    useLazyQuery(LIST_DISCOUNTS, {
      variables: {
        // sort: {},
        pagination: { limit: 10, page: page },
        filter: {},
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        setDiscounts(data?.listDiscounts?.items);
        setDiscountsToDisplay(data?.listDiscounts?.items);
        setIsNextPage(data?.listDiscounts?.hasMore);
        setIsBackPage(page > 1);
      },
    });

  const [
    deisableDiscountFunc,
    { loading: deisableDiscountLoader, error: ddError },
  ] = useMutation(DISABLE_DISCOUNT, {
    variables: {
      discountUUID: discountUUID,
    },
    onCompleted: () => fetchDiscounts(),
  });

  const [
    activateDiscountFunc,
    { loading: activateDiscountLoader, error: adError },
  ] = useMutation(ACTIVATE_DISCOUNT, {
    variables: {
      discountUUID: discountUUID,
    },
    onCompleted: () => fetchDiscounts(),
  });

  React.useEffect(() => {
    if (fdError?.message) {
      dispatch(triggerErr(fdError?.message));
    }
    if (ddError?.message) {
      dispatch(triggerErr(ddError?.message));
    }
    if (adError?.message) {
      dispatch(triggerErr(ddError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fdError, ddError, adError]);

  const onFilterClicked = (e: any) => {
    if (e === 'All') {
      setDiscountsToDisplay(discounts);
      return;
    }
    const newData = getTransactionsByStatus(discounts, e);
    setDiscountsToDisplay(newData);
  };

  const changeDiscountStatus = (status: string, discountUUID: string) => {
    setStatus(status);
    setDiscountUUID(discountUUID);
  };

  const onInputChange = (e: any) => {
    if (!e.target.value.trim()) {
      setDiscountsToDisplay(discounts);
      return;
    }
    const newData = discounts.filter(function (d: any) {
      return d.code === e.target.value;
    });
    setDiscountsToDisplay(newData);
  };

  const onCreateDisCount = () => {
    navigate('/discount-codes/create', { replace: true });
  };

  const discountsTableBody = discountsToDisplay?.map((d: any) => (
    <tr className="all-discounts">
      <td>
        <span className="table__body-td">
          {moment(d?.createdAt).format('LL')}
        </span>
        <span className="all-discounts__sub-date">
          {moment(d?.createdAt).format('LTS')}
        </span>
      </td>
      <td className="all-discounts__body-td">
        {d?.user?.firstName ? (
          <span>
            {d?.user?.firstName} {d?.user?.lastName}
          </span>
        ) : (
          <span>-</span>
        )}
      </td>
      <td>
        <span className="all-discounts__code">{d?.code}</span>
      </td>
      <td>
        <span className="transaction__body-td">{d?.value}</span>
      </td>
      <td>
        <span className="transaction__body-td">{d?.valueType}</span>
      </td>
      <td>{d?.user?.email ? <span>{d?.user?.email}</span> : <span>-</span>}</td>
      <td>
        <span className="transaction__body-td">{d?.maxCount}</span>
      </td>
      <td>{d?.transactionCount}</td>
      <td>
        <span className="transaction__body-td">
          {moment(d?.expireOn).format("MM/DD/YYYY")}
        </span>
      </td>
      <td>
        <span className="transaction__body-td">{d?.ticker}</span>
      </td>
      <td>{d?.valueType === "PERCENTAGE" && d?.value === 100 ? 'Yes' : 'No'}</td>
      <td>
        {(deisableDiscountLoader || activateDiscountLoader) &&
          discountUUID === d?.uuid ? (
          <Loader size={20} />
        ) : (
          <div onClick={() => changeDiscountStatus(d?.status, d?.uuid)}>
            {d?.status === 'ACTIVE' ? (
              <Tooltip title="Disable discount" placement="left-end">
                <HighlightOffSharpIcon
                  style={{ cursor: 'pointer', color: 'red' }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Activate discount" placement="left-end">
                <CheckCircleIcon
                  style={{ cursor: 'pointer', color: 'green' }}
                />
              </Tooltip>
            )}
          </div>
        )}
      </td>
    </tr>
  ));

  return (
    <div className="discounts right-panel-body">
      <PageTitle />
      <div style={{ marginTop: 40 }}></div>
      {getDiscountsLoader || getMeLoader ? (
        <Loader />
      ) : (
        <>
          <div className="all-discounts__header">
            <SearchBox
              placeHolder="Discount code"
              onInputChange={onInputChange}
            />
            <button
              className="all-discounts__btn-approve"
              onClick={onCreateDisCount}
            >
              Create Discount
            </button>
          </div>
          <div style={{ marginTop: 48 }}>
            <Filters
              filters={discountsFilters}
              onFilterClicked={onFilterClicked}
              amount={getAmountArr(discounts)}
            />
          </div>
          <div style={{ marginTop: 48 }}>
            <Table
              titles={discountsTableTitles}
              onButtonClicked={onTableButtonsClicked}
              isNextPage={isNextPage}
              isBackPage={isBackPage}
            >
              {discountsTableBody}
            </Table>
          </div>
        </>
      )}
    </div>
  );
};

export default AllDiscounts;
