import { useLazyQuery } from '@apollo/client';
import React, { useRef, useState } from 'react';
import moment from 'moment';
import Switch from '@mui/material/Switch';

import { LIST_USERS } from '../../graphql/queries/list-users.query';
import Filters from '../filters/filters.component';
import PageTitle from '../page-title/page-title.component';
import SearchBox from '../search-box/search-box.component';
import Table, { ButtonActionType } from '../table/table.component';
import {
  tableUsersTitles,
  usersFilters,
} from './users.helper';

import './users.scss';
import Loader from '../loader/loader.component';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import { userLogin, userLogout } from '../../redux/slices/user/user-slice';
import { GET_ME } from '../../graphql/queries/get-me.query';
import { Tooltip } from '@mui/material';

const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const didMountRef = useRef(false);

  const [getMe, { loading: getMeLoader }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.getMe?.email) {
        dispatch(userLogin(data?.getMe));
        return;
      } else {
        dispatch(userLogout());
        navigate('/login', { replace: true });
      }
    },
  });

  React.useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [usersToDisplay, setUsersToDisplay] = useState([]);
  const [page, setPage] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);
  const [isBackPage, setIsBackPage] = useState(false);
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  const [isPartialPhoneSearch, setIsPartialPhoneSearch] = useState(false)
  const [isReqReady, setIsReqReady] = useState(false);
  const [reset, setReset] = useState(0);
  const [resetInput, setResetInput] = useState(0);

  //actiate pagination (table buttons)
  const onTableButtonsClicked = (action: ButtonActionType) => {
    let pageNumber = 0;
    if (action === ButtonActionType.BACK) {
      pageNumber = page - 1;
    }
    if (action === ButtonActionType.NEXT) {
      pageNumber = page + 1;
    }
    setPage(pageNumber);
  };

  React.useEffect(() => {
    if (page === 0) return;
    fetchUsersMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  React.useEffect(() => {
    if (page === 1) {
      fetchUsersMethod();
      return;
    }
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const onInputChange = (e: any) => {
    setSearch(e.target.value.trim());
    setStatus('');
    if (e.target.value.trim()) {
      setReset(reset + 1);
    }
  };

  React.useEffect(() => {
    if (didMountRef.current) {
      const timeoutId = setTimeout(() => setIsReqReady(true), 300);

      return () => {
        clearTimeout(timeoutId);
        setIsReqReady(false);
      };
    }

    didMountRef.current = true;
  }, [search]);

  React.useEffect(() => {
    if (isReqReady) {
      fetchUsersMethod();
    }
  }, [isReqReady])

  const buildFilter = () => {
    let filter: any = {};
    if (status.trim() !== '') {
      filter = { status };
      return filter
    }
    if (status.trim() === '' && !search.trim()) {
      return {};
    }
    if (!!search.trim() && !isPartialPhoneSearch) {
      filter.fullTextSearch = search;
    }
    if (!!search.trim() && isPartialPhoneSearch) {
      filter.partialPhoneNumber = search;
    }
    return filter;
  }

  const [fetchUsers, { loading: getUsaersLoader, error: guError }] =
    useLazyQuery(LIST_USERS, {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        setUsersToDisplay(data?.listUsers?.items);
        setIsNextPage(data?.listUsers?.hasMore);
        setIsBackPage(page > 1);
      },
    });

  const fetchUsersMethod = () => {
    fetchUsers({
      variables: {
        pagination: {
          limit: 10,
          page: page,
        },
        filter: buildFilter()
      }
    });
  }

  React.useEffect(() => {
    if (guError?.message) {
      dispatch(triggerErr(guError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guError]);

  const onFilterClicked = (e: string) => {
    if (!!search?.trim()) {
      setResetInput(resetInput + 1);
      setSearch('');
    }
    if (e === 'All') {
      setTimeout(() => setStatus(''), 200)
      return;
    }
    setTimeout(() => setStatus(e.toUpperCase()), 200)
  };

  const tableBody = usersToDisplay?.map((u: any) => (
    <tr key={u?.uuid}>
      <td className="table__body-transaction-id">
        <span
          className="table__transaction-id"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(`/user/${u?.uuid}`, { replace: true });
          }}
        >
          {u?.firstName} {u?.lastName}
        </span>
        <span className="table__issuer-name">{u?.email}</span>
      </td>
      <td>
        {u?.mobile?.countryCode ? (
          <span className="table__body-td">
            +{u?.mobile?.countryCode}-{u?.mobile?.localNumber}
          </span>
        ) : (
          <span className="table__body-td">-</span>
        )}
      </td>
      <td className="table__body-transaction-id">
        <span className="table__body-td">
          {moment(u?.createdAt).format('LL')}
        </span>
        <span className="table__issuer-name">
          {moment(u?.createdAt).format('LTS')}
        </span>
      </td>
      <td>
        <span className="table__body-td">
          {u?.status.charAt(0).toUpperCase() + u?.status.slice(1).toLowerCase()}
        </span>
      </td>
    </tr>
  ));

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const handleSwitchChange = (e: any) => setIsPartialPhoneSearch(e.target.checked)

  return (
    <div className="users right-panel-body">
      <PageTitle />
      <div style={{ marginTop: 40 }}>
        <span style={{ fontSize: 14 }}>{isPartialPhoneSearch ? "Disable" : "Enable"} Partial Phone Number Search</span>
        <Tooltip title={`${isPartialPhoneSearch ? "Disable" : "Enable"} Partial Phone Number Search`} placement='top'>
          <Switch {...label} onChange={handleSwitchChange} />
        </Tooltip>
        <SearchBox
          placeHolder={isPartialPhoneSearch ? "PARTIAL PHONE NUMBER" : "USER NAME OR EMAIL OR COMPLETE PHONE"}
          onInputChange={onInputChange}
          resetInput={resetInput}
        />
        <div style={{ marginTop: 48 }}>
          <Filters
            filters={usersFilters}
            onFilterClicked={onFilterClicked}
            amount={[]}
            reset={reset}
          />
        </div>
        {getUsaersLoader || getMeLoader ? (
          <Loader />
        ) : (
          <div style={{ marginTop: 48 }}>
            <Table
              titles={tableUsersTitles}
              onButtonClicked={onTableButtonsClicked}
              isNextPage={isNextPage}
              isBackPage={isBackPage}
            >
              {tableBody}
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
