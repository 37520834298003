/* eslint-disable no-use-before-define */
import { gql } from '@apollo/client';

export const ADD_DOCUMENT = gql`
    mutation addDocument(
        $name: String!
        $transactionUUID: String!
    ) {
        addDocument(
            type: OTHERDOCUMENT
		    name: $name
		    transactionUUID: $transactionUUID
        ) {
            uuid
        }
    }
`;


export const GET_DOC_UPLOAD_URL = gql`
  mutation generateDocumentUploadURL(
    $documentUUID: String!
    $transactionUUID: String!
  ) {
    generateDocumentUploadURL(
      transactionUUID: $transactionUUID
      documentUUID: $documentUUID
      documentFileType: PNG
    )
  }
`;

export const NOTIFY_DOC_UPLOADED = gql`
  mutation notifyDocumentUploaded(
    $documentUUID: String!
    $transactionUUID: String!
  ) {
    notifyDocumentUploaded(
      transactionUUID: $transactionUUID
      documentUUID: $documentUUID
    ) {
      uuid
      name
      status
      documentType {
        uuid
        type
      }
    }
  }
`;
