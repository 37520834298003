import React, { useState } from 'react';
import moment from 'moment';

import './transaction-details.scss';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GE_COVER_LETTER_URL } from '../../graphql/queries/get-cover-letter.query';
import Loader from '../loader/loader.component';
import { useDispatch } from 'react-redux';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import { GET_USER } from '../../graphql/queries/get-user.query';
import { useNavigate } from 'react-router-dom';
import { GET_RETURN_FEDEX_LABEL_URL } from '../../graphql/queries/get-return-fedex-label-url.query';
import { ROLLBACK_SUSPICIOUS_TRANSACTION } from '../../graphql/mutations/rollback-suspicious-transaction.mutation';

type TransactionDetailsType = {
  transaction: any;
};

const TransactionDetails: React.FC<TransactionDetailsType> = ({
  transaction,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [returnShippingUUID, setReturnShippingUUID] = useState<any>('');

  useQuery(GET_USER, {
    variables: {
      userUUID: transaction?.user?.uuid,
    },
    onCompleted: (data: any) => {
      if (data?.getUser) {
        setUser(data?.getUser);
      }
    },
  });

  const [
    getCovverLetterFunc,
    { loading: getCoverLetterUrlLoader, error: gclError },
  ] = useLazyQuery(GE_COVER_LETTER_URL, {
    variables: {
      transactionUUID: transaction?.uuid,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.getCoverLetterUrl) {
        window.open(data?.getCoverLetterUrl, '_blank');
      }
    },
  });

  React.useEffect(() => {
    if (gclError?.message) {
      dispatch(triggerErr(gclError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gclError]);

  const [getFedexLabelURL, { loading: getFedexLabelLoader, error: gflError }] =
    useLazyQuery(GET_RETURN_FEDEX_LABEL_URL, {
      variables: {
        returnShippingUUID: returnShippingUUID,
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        if (data?.getReturnFedexLabelUrl) {
          window.open(data?.getReturnFedexLabelUrl, '_blank');
        }
      },
    });

  React.useEffect(() => {
    if (returnShippingUUID === '') return;
    getFedexLabelURL();
  }, [returnShippingUUID]);

  const [rollbackSuspiciousTransactionFunc, { loading: rollbackSuspiciousTransactionLoading, error: rollbackSuspiciousTransactionError }] = useMutation(
    ROLLBACK_SUSPICIOUS_TRANSACTION,
    {
      variables: {
        transactionUUID: transaction.uuid,
      },
      onCompleted: (data) => {
        window.location.reload()
      }
    }
  );

  const rollbackStatus = async () => {
    try {
      await rollbackSuspiciousTransactionFunc();
    }
    catch (err) {
      alert(err)
    }
  }

  const btnStyle = {
    height: 24,
    border: "none",
    background: "#28DAB6",
    borderRadius: 2,
    cursor: "pointer",
    color: "#fff"
  }

  return (
    <div className="transaction-details">
      <div className="transaction-details__col">
        <label className="transaction-details__title">Transaction ID</label>
        <p className="transaction-details__id">{transaction?.code}</p>
        {transaction?.status === "PENDING" || transaction?.status === "REVIEWING" || transaction?.status === "COMPLETED" ? <label
          className="transaction-details__green-label pointer"
          onClick={async () => {
            await getCovverLetterFunc();
          }}
          style={{
            display: "flex"
          }}
        >
          {getCoverLetterUrlLoader ? (
            <Loader size={20} />
          ) : (
            <span style={{ marginBottom: 18 }}>Checklist Instructions</span>
          )}
        </label> : ""}
        {transaction?.shippings && transaction?.shippings[0]?.provider === "FEDEX" ? (
          getFedexLabelLoader ? <Loader size={20} /> : <label className="transaction-details__green-label pointer" onClick={() => setReturnShippingUUID(transaction?.shippings[0].uuid)}>
            Fedex Label
          </label>
        ) : (
          ''
        )}
      </div>

      <div className="transaction-details__col">
        <label className="transaction-details__title">Username</label>
        <label className="transaction-details__green-label pointer" onClick={() => {
          navigate(`/user/${transaction?.user?.uuid}`);
        }}>
          {`${transaction?.user?.firstName} ${transaction?.user?.lastName}`}
        </label>
        <div className="transaction-details__separator transaction-details__col">
          <label className="transaction-details__title">Email</label>
          <label className="transaction-details__regular-title">
            {transaction?.user?.email}
          </label>
        </div>
        <div className="transaction-details__separator transaction-details__col">
          <label className="transaction-details__title">Cell Phone</label>
          {user?.mobile?.countryCode ? (
            <label className="transaction-details__regular-title">
              +{user?.mobile?.countryCode}-{user?.mobile?.localNumber}
            </label>
          ) : (
            '-'
          )}
        </div>
        <div className="transaction-details__separator transaction-details__col">
          <label className="transaction-details__title">Home Phone</label>
          {user?.homePhone?.countryCode ? (
            <label className="transaction-details__regular-title">
              +{user?.homePhone?.countryCode}-{user?.homePhone?.localNumber}
            </label>
          ) : (
            '-'
          )}
        </div>
        <div className="transaction-details__separator transaction-details__col">
          <label className="transaction-details__title">Work Phone</label>
          {user?.workPhone?.countryCode ? (
            <label className="transaction-details__regular-title">
              +{user?.workPhone?.countryCode}-{user?.workPhone?.localNumber}
            </label>
          ) : (
            '-'
          )}
        </div>
      </div>

      <div className="transaction-details__col">
        {transaction?.shippings?.length && transaction?.shippings[0]?.fedex ? (
          <>
            <label className="transaction-details__title">
              Fedex Tracking Number
            </label>
            <label className="transaction-details__green-label pointer">
              {JSON.stringify(transaction?.shippings[0]?.fedex)}
            </label>
          </>
        ) : (
          ''
        )}

        <div className="transaction-details__separator transaction-details__col">
          <label className="transaction-details__title">Requested On</label>
          <label className="transaction-details__regular-title">
            {moment(transaction?.createdAt).format('LL')}{' '}
            {moment(transaction?.createdAt).format('LTS')}
          </label>
        </div>

        {transaction?.completedOn && transaction?.status === 'COMPLETED' && <div className="transaction-details__separator transaction-details__col">
          <label className="transaction-details__title">Completed On</label>
          {transaction?.completedOn ? <label className="transaction-details__regular-title">
            {moment(transaction.completedOn).format('LL')}{' '}
            {moment(transaction.completedOn).format('LTS')}
          </label> : ''}
        </div>}

        <div className="transaction-details__separator transaction-details__col">
          <label className="transaction-details__title">Status</label>
          <label className="transaction-details__regular-title" style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <span>{transaction?.status} </span>
            {transaction.status === "SUSPICIOUS" ?
              <>
                {rollbackSuspiciousTransactionLoading ? (
                  <Loader size={20} />
                ) : (
                  <button style={btnStyle} onClick={() => rollbackStatus()}>Revert status to REVIEWING</button>
                )}
              </>
              :
              null}
          </label>
        </div>

        <div className="transaction-details__separator transaction-details__col">
          <label className="transaction-details__title">Transfer Agent</label>
          <label className="transaction-details__regular-title">
            {transaction?.transferAgent?.name || '-'}
          </label>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetails;
