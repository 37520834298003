import { TabType } from '../navigator/navigator.component';

export const userDetails: TabType[] = [
  { name: 'Users', navigateTo: '/users', isLeaf: true },
  { name: 'User Details', navigateTo: '', isLeaf: false },
];

export const userDetailsFilters = [
  'Transactions',
  'Update',
  'Notes',
  'Validation',
  'Delete',
  'Lock',
  'Idology'
];
