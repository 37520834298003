import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { GET_ME } from '../../graphql/queries/get-me.query';
import { GET_USER } from '../../graphql/queries/get-user.query';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import { userLogin, userLogout } from '../../redux/slices/user/user-slice';
import DeleteUser from '../delete-user/delete-user.component';
import Filters from '../filters/filters.component';
import Loader from '../loader/loader.component';
import Navigator from '../navigator/navigator.component';
import Notes from '../notes/notes.component';
import UpdateUser from '../update-user/update-user.component';
import UserDetails from '../user-details/user-details.component';
import UserIdology from '../user-idology/user-idology.component';
import UserTransactions from '../user-transactions/user-transactions.component';
import UserValidation from '../user-validation/user-validation.component';
import { userDetails, userDetailsFilters } from './user.helper';

import './user.scss';

const User = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getMe, { loading: getMeLoader }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.getMe?.email) {
        dispatch(userLogin(data?.getMe));
        return;
      } else {
        dispatch(userLogout());
        navigate('/login', { replace: true });
      }
    },
  });

  React.useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [userUUID, setUserUUID] = useState<any>('');
  const [user, setUser] = useState<any>({});
  const [userComponent, setUserComponent] = useState<string>('Transactions');

  const location = useLocation();

  const [getUser, { loading: getUserLoader, error: guError }] =
    useLazyQuery(GET_USER);

  React.useEffect(() => {
    if (guError?.message) {
      dispatch(triggerErr(guError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guError]);

  React.useEffect(() => {
    let arr = location.pathname.split('/');
    const uuid = arr[arr?.length - 1];
    setUserUUID(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    if (userUUID === '') return;
    try {
      getUser({
        variables: {
          userUUID: userUUID,
        },
        fetchPolicy: 'no-cache',
        onCompleted: async (data: any) => {
          setUser(data?.getUser);
        },
      });
    } catch (err) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUUID]);

  const onFilterClicked = (e: any) => {
    setUserComponent(e);
  };

  const userComponents: { [key: string]: any } = {
    Transactions: <UserTransactions />,
    Update: <UpdateUser />,
    Notes: <Notes isUser={true} />,
    Validation: <UserValidation validations={user?.idologyLogs} />,
    Delete: <DeleteUser isLockButton={false} user={user} />,
    Lock: <DeleteUser isLockButton={true} user={user} />,
    Idology: <UserIdology user={user} />
  };

  return (
    <>
      {getUserLoader || getMeLoader ? (
        <div className="user__loader">
          <Loader />
        </div>
      ) : (
        <div className="user right-panel-body">
          <Navigator tabs={userDetails} />
          <UserDetails user={user} />
          <hr className="hr user__hr" />
          <Filters
            filters={userDetailsFilters}
            onFilterClicked={onFilterClicked}
            amount={[]}
          />
          <div style={{ marginTop: 48 }}>{userComponents[userComponent]}</div>
        </div>
      )}
    </>
  );
};

export default User;
