import { gql } from '@apollo/client';

export const GET_IDENTITY_QUESTIONS = gql`
query getIdentityQuestions($userUUID: String!){
	getIdentityQuestions(userUUID: $userUUID) {
		questions {
			order
			prompt
			answers
			type
		}
		status
		answerType
	}
}`;