import { gql } from '@apollo/client';

export const SEND_IDOLOGY_ANSWERS = gql`
mutation sendIdentityAnswers($answers: [IdologyAnswer!]! $type: AnswerType! $userUUID: String!) {
	sendIdentityAnswers(
        input: {
            answers: $answers
            type: $type
        }
		userUUID: $userUUID
	) {
		uuid
        status
		idologyResponse {
			answerType
            idScan
            idScanUrl
            questions {
                order
                prompt
                answers
                type
            }
            status
		}
	}
}
`