export const usersFilters = ['All', 'Active', 'Pending', 'Verifying', 'Locked'];

export const tableUsersTitles = [
  'Name & Email',
  'Phone',
  'Registered',
  'Status',
  'Uses invoice',
];

export const getUsersByEmailOrName = (users: any, emailOrName: string) => {
  return users?.filter(function (u: any) {
    return (
      u?.email?.toLowerCase() === emailOrName.toLowerCase() ||
      u?.firstName?.toLowerCase() === emailOrName.toLowerCase() ||
      u?.lastName?.toLowerCase() === emailOrName.toLowerCase()
    );
  });
};

export const getAmountArr = (users: any = [], total?: number) => {
  return [
    total ?? 0,
    getUsersByStatus(users, 'Active')?.length,
    getUsersByStatus(users, 'Pending')?.length,
    getUsersByStatus(users, 'Verifying')?.length,
    getUsersByStatus(users, 'Locked')?.length,
  ];
};

export const getUsersByStatus = (users: any, status: string) => {
  return users?.filter(function (u: any) {
    return u?.status?.toLowerCase() === status.toLowerCase();
  });
};
