import { gql } from '@apollo/client';

// pagination - { limit: 10, page: 1 }

export const LIST_DISCOUNTS = gql`
  query listDocuments($pagination: Pagination, $filter: ListDiscountFilter) {
    listDiscounts(filter: $filter, pagination: $pagination) {
      items {
        uuid
        code
        valueType
        value
        status
        rules
        ticker
        createdAt
        transactionCount
        user {
          uuid
          email
          firstName
          lastName
        }
        expireOn
        maxCount
      }
      total
      hasMore
    }
  }
`;
