/* eslint-disable no-use-before-define */
import { gql } from '@apollo/client';

// for pagination { limit: 10, page: 1 }
// for filter { userUUID: "eae53c18-ab09-4970-823b-bba31d3c11eb" }
// for sort createdAt: ASC, updatedAt: DESC

export const LIST_TRANSACTIONS = gql`
  query listTransactions(
    $pagination: Pagination
    $filter: ListTransactionsFilter
    $sort: TransactionSortFields
  ) {
    listTransactions(pagination: $pagination, filter: $filter, sort: $sort) {
      items {
        uuid
        code
        stockholderType
        format
        securityType
        createdAt
        status
        certificates {
          uuid
          symbol
          medallionsCount
        }
        notes {
          uuid
          content
          createdAt
        }
        transferAgent {
          uuid
          name
        }
        shippings {
          uuid
          from {
            addressLine1
            addressLine2
            state
            zip
          }
          to {
            addressLine1
            addressLine2
            state
            zip
          }
          type
          provider
          createdAt
        }
        discounts {
          code
          valueType
          value
        }
        charge {
          status
          type
          provider
          totalDiscountsInCents
          totalMedallionCostInCents
          totalShippingCostInCents
        }
        totalRefundsInCents
        user {
          uuid
          firstName
          lastName
          status
        }
      }
      total
      hasMore
    }
  }
`;
