import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ADD_NOTE } from '../../graphql/mutations/add-note.mutation';
import { ADD_USER_NOTE } from '../../graphql/mutations/add-user-note.mutation';
import { GET_TRANSACTION } from '../../graphql/queries/get-transaction.query';
import { GET_USER } from '../../graphql/queries/get-user.query';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import Loader from '../loader/loader.component';
import Note, { INote } from '../note/note.component';

import './notes.scss';

type NotesType = {
  isUser?: boolean;
};

const Notes: React.FC<NotesType> = ({ isUser = false }) => {
  const [transactionUUID, setTransactionUUID] = useState<any>('');
  const [userUUID, setUserUUID] = useState<any>('');
  const [transaction, setTransaction] = useState<any>({});
  const [newNote, setNewNote] = useState<any>('');

  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    let arr = location.pathname.split('/');
    const uuid = arr[arr?.length - 1];
    if (isUser) {
      setUserUUID(uuid);
    } else {
      setTransactionUUID(uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    if (isUser && userUUID === '') return;
    if (!isUser && transactionUUID === '') return;
    fetchTransaction();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionUUID, userUUID]);

  const fetchTransaction = () => {
    try {
      if (isUser) {
        getUser({
          variables: {
            userUUID: userUUID,
          },
          fetchPolicy: 'no-cache',
          onCompleted: async (data: any) => {
            setTransaction(data?.getUser);
          },
        });
      } else {
        getTransaction({
          variables: {
            transactionUUID: transactionUUID,
          },
          fetchPolicy: 'no-cache',
          onCompleted: (data: any) => setTransaction(data?.getTransaction),
        });
      }
    } catch (err) {}
  };

  const [getTransaction, { loading: getTransactionLoader, error: gtError }] =
    useLazyQuery(GET_TRANSACTION);

  const [getUser, { loading: getUserLoader, error: guError }] =
    useLazyQuery(GET_USER);

  const [addNoteFunc, { loading: addNoteLoader, error: anError }] = useMutation(
    ADD_NOTE,
    {
      variables: { content: newNote, transactionUUID: transactionUUID },
      onCompleted: () => fetchTransaction(),
    }
  );
  const [addUserNoteFunc, { loading: addUserNoteLoader, error: aunError }] =
    useMutation(ADD_USER_NOTE, {
      variables: { content: newNote, userUUID: userUUID },
      onCompleted: () => fetchTransaction(),
    });

  React.useEffect(() => {
    if (aunError?.message) {
      dispatch(triggerErr(aunError?.message));
    }
    if (anError?.message) {
      dispatch(triggerErr(anError?.message));
    }
    if (guError?.message) {
      dispatch(triggerErr(guError?.message));
    }
    if (gtError?.message) {
      dispatch(triggerErr(gtError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aunError, anError, guError, gtError]);

  const addNote = async () => {
    if (newNote === '') return;
    try {
      if (isUser) {
        await addUserNoteFunc();
      } else {
        addNoteFunc();
      }
    } catch (err) {}
  };

  return (
    <>
      {getTransactionLoader || getUserLoader ? (
        <div className="notes__loader">
          <Loader />
        </div>
      ) : (
        <div className="notes">
          <textarea
            className="notes__textarea"
            placeholder="Your Note"
            onChange={(e) => {
              setNewNote(e.target.value);
            }}
          />
          <button
            className="notes__button"
            type="button"
            onClick={() => {
              addNote();
            }}
          >
            {addNoteLoader || addUserNoteLoader ? (
              <Loader size={20} />
            ) : (
              'Add Note'
            )}
          </button>

          {transaction?.notes?.length ? (
            <hr className="hr" style={{ marginTop: 25 }} />
          ) : (
            ''
          )}

          {transaction?.notes?.length ? (
            transaction?.notes?.map((n: INote) => <Note note={n} />)
          ) : (
            <h4>No notes available</h4>
          )}
        </div>
      )}
    </>
  );
};

export default Notes;
