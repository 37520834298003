import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ADJUST_RETURN_ADDRESS } from '../../graphql/mutations/adjust-return-address.mutation';
import { GET_ADJUSTED_RETURN_SHIPPING } from '../../graphql/queries/get-adjusted-return-shipping.query';
import { GET_TRANSACTION } from '../../graphql/queries/get-transaction.query';
import { GET_USER } from '../../graphql/queries/get-user.query';
import { LIST_ADJUSTED_RETURN_ADDRESSES } from '../../graphql/queries/list-adjusted-return-addresses.query';
import { LIST_TRANSFER_AGENTS } from '../../graphql/queries/list-transfer-agents.query';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import AdjustedAdressesDropdown from '../adjusted-addresses-dropdown/adjusted-addresses-dropdown.component';
import AddTransferAgentAddressType from '../add-transfer-agent-address/add-transfer-agent-address.component';
import Loader from '../loader/loader.component';

import './shipping-info.scss';
import {
  adminLogs,
  INITIAL_ADJUSTED_ADDRESS_VALUES,
  INITIAL_ADMIN_CHOSEN_ADDRESS,
  validateAdjustObj,
} from './shipping-infp.helper';

const ShippingInfo = () => {
  const [adjustedAddresses, setAdjustedAddresses] = useState([]);
  const [transactionUUID, setTransactionUUID] = useState<any>('');
  const [transaction, setTransaction] = useState<any>({});
  const [isReadyForRequest, setIsReadyForRequest] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [userUUID, setUserUUID] = useState<any>('');
  const [user, setUser] = useState<any>({});
  const [adminChosedAddress, setAdminChosedAddress] = useState<adminLogs>(
    INITIAL_ADMIN_CHOSEN_ADDRESS
  );
  const [adjustedAddress, setAdjustedAddress] = useState(
    INITIAL_ADJUSTED_ADDRESS_VALUES
  );

  const [getUser, { loading: getUserLoader, error: guError }] =
    useLazyQuery(GET_USER);

  React.useEffect(() => {
    if (userUUID === '') return;
    try {
      getUser({
        variables: {
          userUUID: userUUID,
        },
        fetchPolicy: 'no-cache',
        onCompleted: async (data: any) => {
          setUser(data?.getUser);
        },
      });
    } catch (err) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUUID]);

  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    let arr = location.pathname.split('/');
    const uuid = arr[arr?.length - 1];
    setTransactionUUID(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    if (transactionUUID === '') return;
    fetchTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionUUID]);

  const fetchTransaction = () => {
    try {
      getTransaction({
        variables: {
          transactionUUID: transactionUUID,
        },
        fetchPolicy: 'no-cache',
        onCompleted: async (data: any) => {
          setTransaction(data?.getTransaction);
          setUserUUID(data?.getTransaction?.user?.uuid)
          await getAdjustedAddress();
        },
      });
    } catch (err) { }
  };

  const [
    getTransaction,
    { loading: getTransactionLoader, error: getTransactionsError },
  ] = useLazyQuery(GET_TRANSACTION);

  const baseVariablesForMutation = {
    transactionUUID: transactionUUID,
    addressLine1: adjustedAddress.addressLine1,
    addressLine2: adjustedAddress.addressLine2,
    city: adjustedAddress.city,
    state: adjustedAddress.state,
    zip: adjustedAddress.zip,
  };

  const [adjustAddressFunc, { loading: adjustAddressLoader, error: aaError }] =
    useMutation(ADJUST_RETURN_ADDRESS, {
      variables: isChecked
        ? { ...baseVariablesForMutation, name: adjustedAddress.name }
        : baseVariablesForMutation,
      onCompleted: () => {
        setIsReadyForRequest(false);
        setAdjustedAddress(INITIAL_ADJUSTED_ADDRESS_VALUES);
        fetchTransaction();
      },
    });

  const onAdjustAddress = () => {
    if (!validateAdjustObj(adjustedAddress)) return;
    if (
      adjustedAddress.name !== '' &&
      adjustedAddresses.some((a: any) => a?.name === adjustedAddress.name)
    ) {
      alert('Name is already exists!');
      return;
    }
    if (isChecked && adjustedAddress.name === '') {
      alert('Please choose name');
      return;
    }
    setIsReadyForRequest(true);
  };

  const onAddressTyped = (e: any) => {
    const { value, name } = e.target;

    setAdjustedAddress({ ...adjustedAddress, [name]: value });
  };

  React.useEffect(() => {
    if (!validateAdjustObj(adjustedAddress)) return;
    if (!isReadyForRequest) return;
    adjustAddressFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyForRequest]);

  const { loading: listAdjustedAdressesLoader, error: laaError } = useQuery(
    LIST_ADJUSTED_RETURN_ADDRESSES,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        setAdjustedAddresses(data?.listAdjustedReturnAddresses);
        getTransferAgetnsAddresses();
      },
    }
  );

  const [getTransferAgetnsAddresses, { loading: listTransferAgentsLoader, error: listTransferAgentsError }] = useLazyQuery(
    LIST_TRANSFER_AGENTS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        const transferAgents = data.listTransferAgents.filter((transferAgent: any) => transferAgent.addresses?.length > 0);
        const addresses: any = transferAgents.map((transferAgent: any) => ({ ...transferAgent.addresses[0], name: transferAgent?.name }))
        setAdjustedAddresses([...adjustedAddresses, ...addresses] as any)
      },
    }
  );

  const [
    getAdjustedAddress,
    { loading: getAdjustedAdressesLoader, error: gaaError },
  ] = useLazyQuery(GET_ADJUSTED_RETURN_SHIPPING, {
    variables: {
      transactionUUID: transactionUUID,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.getAdjustedReturnAddress) {
        setAdminChosedAddress(data?.getAdjustedReturnAddress);
      }
    },
  });

  React.useEffect(() => {
    if (gaaError?.message) {
      dispatch(triggerErr(gaaError?.message));
    }
    if (laaError?.message) {
      dispatch(triggerErr(laaError?.message));
    }
    if (aaError?.message) {
      dispatch(triggerErr(aaError?.message));
    }
    if (getTransactionsError?.message) {
      dispatch(triggerErr(getTransactionsError?.message));
    }
    if (listTransferAgentsError?.message) {
      dispatch(triggerErr(listTransferAgentsError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaaError, laaError, aaError, getTransactionsError, listTransferAgentsError]);

  const onPickAddressFromDropdown = (e: any) => {
    setAdjustedAddress(e);
    setIsChecked(false);
  };

  const reloadData = () => {
    window.location.reload();
  }

  return (
    <>
      {getTransactionLoader ? (
        <div className="shipping-info__loader">
          <Loader />
        </div>
      ) : (
        <div className="shipping-info">
          <div className="shipping-info__details">
            {getUserLoader ? <Loader /> : <div>
              <label className="shipping-info__details--title">
                Home Address
              </label>
              <div className="shipping-info__details--address">
                <p>{user?.home?.addressLine1}</p>
                <p>{user?.home?.state} </p>
                <p>{user?.home?.city} </p>
                <p>{user?.home?.zip}</p>
              </div>
            </div>}
            <div>
              <label className="shipping-info__details--title">
                Return Shipping Address
              </label>
              {transaction?.shippings?.length ? (
                <div className="shipping-info__details--address">
                  <p>
                    {transaction?.shippings[0]?.type === 'SHIP'
                      ? transaction?.shippings[0]?.from?.addressLine1
                      : transaction?.shippings[0]?.to?.addressLine1}
                  </p>
                  <p>
                    {transaction?.shippings[0]?.type === 'SHIP'
                      ? transaction?.shippings[0]?.from?.state
                      : transaction?.shippings[0]?.state}
                  </p>
                  <p>
                    {transaction?.shippings[0]?.type === 'SHIP'
                      ? transaction?.shippings[0]?.from?.city
                      : transaction?.shippings[0]?.to?.city}
                  </p>
                  <p>
                    {transaction?.shippings[0]?.type === 'SHIP'
                      ? transaction?.shippings[0]?.from?.zip
                      : transaction?.shippings[0]?.to?.zip}
                  </p>
                </div>
              ) : (
                <div>---</div>
              )}
            </div>
            {getAdjustedAdressesLoader ? (
              <Loader />
            ) : (
              <div>
                <label className="shipping-info__details--title">
                  Admin Adjusted Address
                </label>
                {adminChosedAddress?.addressLine1 !== '' ? (
                  <div className="shipping-info__details--address">
                    <p>{adminChosedAddress?.addressLine1}</p>
                    <p>{adminChosedAddress?.state}</p>
                    <p>{adminChosedAddress?.city}</p>
                    <p>{adminChosedAddress?.zip}</p>
                  </div>
                ) : (
                  <div>---</div>
                )}
              </div>
            )}
          </div>

          <div className="shipping-info__change-box">
            <b className="shipping-info__details--title">
              Change Return Address
            </b>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 24,
              }}
            >
              <div>
                <input
                  type={'checkbox'}
                  checked={isChecked}
                  onClick={(e: any) => {
                    setIsChecked(e.target.checked);
                    if (e.target.checked) {
                      setAdjustedAddress(INITIAL_ADJUSTED_ADDRESS_VALUES);
                    }
                  }}
                />
                <input
                  className="input"
                  style={{ maxWidth: 120 }}
                  placeholder="Name"
                  name="name"
                  value={adjustedAddress.name}
                  onChange={onAddressTyped}
                  disabled={!isChecked}
                />
              </div>
              {listAdjustedAdressesLoader || listTransferAgentsLoader ? (
                <Loader size={30} />
              ) : (
                <AdjustedAdressesDropdown
                  setAdjusted={onPickAddressFromDropdown}
                  adjustedAddresses={adjustedAddresses}
                />
              )}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 24,
              }}
            >
              <input
                className="input"
                style={{ maxWidth: 230 }}
                placeholder="Street Address Line 1"
                name="addressLine1"
                value={adjustedAddress.addressLine1}
                onChange={onAddressTyped}
              />
              <input
                className="input"
                style={{ maxWidth: 230 }}
                placeholder="Street Address Line 2"
                name="addressLine2"
                value={adjustedAddress.addressLine2}
                onChange={onAddressTyped}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 24,
              }}
            >
              <input
                className="input"
                style={{ maxWidth: 120 }}
                placeholder="City"
                name="city"
                value={adjustedAddress.city}
                onChange={onAddressTyped}
              />
              <input
                className="input"
                style={{ maxWidth: 100 }}
                placeholder="State"
                name="state"
                value={adjustedAddress.state}
                onChange={onAddressTyped}
              />
              <input
                className="input"
                style={{ maxWidth: 150 }}
                placeholder="Zip"
                name="zip"
                value={adjustedAddress.zip}
                onChange={onAddressTyped}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 24,
              }}
            >
              <button className="shipping-info__btn" onClick={onAdjustAddress}>
                {adjustAddressLoader ? <Loader size={30} /> : 'Change'}
              </button>
            </div>
          </div>

          <AddTransferAgentAddressType onCompleteAddNewTransferAgentAddress={reloadData} />
        </div>
      )}
    </>
  );
};

export default ShippingInfo;
