import { gql } from '@apollo/client';

export const LIST_VERIFICATION_CODES = gql`
  query listVerificationCodesSent($transactionUUID: String!, $page: Float) {
    listVerificationCodesSent(
      transactionUUID: $transactionUUID
      page: $page
      limit: 10
    ) {
      items {
        verificationCodeUUID
        sentAt
        status
        phoneNumber
        carrier {
          name
          type
        }
      }
      hasMore
      total
    }
  }
`;
