/* eslint-disable no-use-before-define */
import { gql } from '@apollo/client';

export const LIST_TRANSFER_AGENTS = gql`
    query {
        listTransferAgents {
            uuid
            name
            addresses {
                uuid
                state
                addressLine1
                addressLine2
                city
                country
                createdAt
                isDefault
                name
                state
                type
                updatedAt
                uuid
                zip
            }
        }
    }
`;
