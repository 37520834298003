import { gql } from "@apollo/client";

export const DENY_APPROVED_MEDALLIONS = gql`
    mutation denyApprovedMedallions($transactionUUID: String!, $medallionUUIDs: [String!]!){
        denyApprovedMedallions(
            transactionUUID: $transactionUUID
            medallionUUIDs: $medallionUUIDs
        ) {
            uuid
            status
            costInCents
            included
        }
    }`;