export const transactionsFilters = [
  'All',
  'Recently uploaded',
  'Creating',
  'Pending',
  'Reviewing',
  'Completed',
  'Suspicious',
  'Cancelled',
  'Deleted',
  'With declined medallions',
  'With reviewing medallions',
  'With Refunds',
  'All Medallions Declined'
];

export const getDefaultCheckIndex = (name: string) => {
  return transactionsFilters.indexOf(name);
};

export const tableTransactionsTitles = [
  'Transaction ID',
  'Certificates',
  'Medallions',
  'Amount',
  'Date',
  'Status',
];

export const getAmountArr = (t: any, total: number) => {
  return [
    total,
    getTransactionsByStatus(t, 'Reviewing')?.length,
    getTransactionsByStatus(t, 'Creating')?.length,
    getTransactionsByStatus(t, 'Pending')?.length,
    getTransactionsByStatus(t, 'Reviewing')?.length,
    getTransactionsByStatus(t, 'Completed')?.length,
    getTransactionsByStatus(t, 'Suspicious')?.length,
    getTransactionsByStatus(t, 'Cancelled')?.length,
    getTransactionsByStatus(t, 'Deleted')?.length,
    undefined,
    undefined,
    undefined,
  ];
};

export const getTransactionsByStatus = (transactions: any, status: string) => {
  return transactions?.filter(function (t: any) {
    return t?.status?.toLowerCase() === status.toLowerCase();
  });
};

export const getRefundedTransactions = (transactions: any) => {
  return transactions?.filter((t: any) => {
    return t?.refunds !== null;
  });
};

export const filterByMedallionStatus = (transactions: any, status: string) => {
  const certificates = transactions?.reduce((acc: any, t: any) => {
    return [...acc, ...t.certificates];
  }, []);
  const requested = certificates.filter((c: any) => {
    const isMedallions = c?.medallions?.filter(
      (m: any) => m?.status === status
    );
    return isMedallions?.length;
  });
  if (requested?.length) {
    const test = requested.map((r: any) => {
      return r.uuid;
    });
    const cRequesetd = transactions?.filter((t: any) => {
      const certificates = t?.certificates?.filter((c: any) =>
        test.includes(c?.uuid)
      );
      return certificates?.length;
    });
    return cRequesetd;
  }
  return [];
};
