import { createSlice } from '@reduxjs/toolkit';

export const ErrorHandlingSlice = createSlice({
  name: 'errors',
  initialState: {
    error: '',
    success: '',
  },
  reducers: {
    triggerErr: (state, action: any) => {
      state.error = action.payload;
    },
    triggerSuccess: (state, action: any) => {
      state.success = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { triggerErr, triggerSuccess } = ErrorHandlingSlice.actions;

export default ErrorHandlingSlice.reducer;
