import React from 'react';

const SearchIcon = ({
  height = '100%',
  width = '100%',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.4">
        <path
          d="M13.7138 4.8382C16.1647 7.28913 16.1647 11.2629 13.7138 13.7138C11.2629 16.1647 7.28913 16.1647 4.8382 13.7138C2.38727 11.2629 2.38727 7.28913 4.8382 4.8382C7.28913 2.38727 11.2629 2.38727 13.7138 4.8382"
          stroke="#353535"
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M17 17L13.71 13.71"
          stroke="#353535"
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
