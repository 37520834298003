import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { UPDATE_USER_NAME } from '../../graphql/mutations/update-user-name.mutation';
import { GET_USER } from '../../graphql/queries/get-user.query';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import Loader from '../loader/loader.component';

import './update-user.scss';

const UpdateUser = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [userUUID, setUserUUID] = useState<any>('');

  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    let arr = location.pathname.split('/');
    const uuid = arr[arr?.length - 1];
    setUserUUID(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [
    updateUserNameFunc,
    { loading: updateUserNameLoader, error: uunError },
  ] = useMutation(UPDATE_USER_NAME, {
    variables: {
      userUUID: userUUID,
      firstName: firstName,
      lastName: lastName,
    },
    refetchQueries: [
      {
        query: GET_USER,
        variables: { userUUID },
      },
    ],
  });

  React.useEffect(() => {
    if (uunError?.message) {
      dispatch(triggerErr(uunError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uunError]);

  const update = async () => {
    if (firstName.trim() === '' || lastName.trim() === '' || userUUID === '')
      return;
    await updateUserNameFunc();
  };

  return (
    <div className="update-user">
      <div className="update-user__change-box">
        <b className="update-user__details--title">Update User Name</b>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 24,
            width: '100%',
            alignItems: 'center',
          }}
        >
          <input
            className="input"
            placeholder="First name"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <input
            className="input"
            style={{ marginTop: 25 }}
            placeholder="Last name"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 24,
          }}
        >
          <button className="update-user__btn" onClick={update}>
            {updateUserNameLoader ? <Loader size={30} /> : 'Update'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;
