import React from 'react';
import { useNavigate } from 'react-router-dom';
import RightArrow from '../right-arrow/right-arrow.component';

import './navigator.scss';

export type TabType = { name: string; navigateTo: string; isLeaf: boolean };

type NavigatorType = {
  tabs: TabType[];
};

const Navigator: React.FC<NavigatorType> = ({ tabs }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="navigator">
        {tabs.map((tab: TabType, i: number) => (
          <React.Fragment key={i}>
            <span
              onClick={() => {
                if (!tab.isLeaf) return;
                navigate(`${tab.navigateTo}`, { replace: true });
              }}
              className={`navigator__name ${tab.isLeaf ? 'pointer' : ''}`}
            >
              {tab.name}
            </span>
            {tab.isLeaf && (
              <div className="navigator__arrow">
                <RightArrow />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      <hr className="navigator__hr"></hr>
    </>
  );
};

export default Navigator;
