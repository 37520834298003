import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';

import { LOGIN } from '../../graphql/mutations/login.mutation';
import { userLogin, userLogout } from '../../redux/slices/user/user-slice';
import Loader from '../loader/loader.component';

import './login.scss';
import { GOOGLE_LOGIN } from '../../graphql/mutations/google-login.mutation';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
  });

  const [googleRes, setGoogleRes] = React.useState({
    id_token: '',
    access_token: '',
  });

  //DEV
  // const clientId = '187693633132-98bmsnrq297irkgaqflmtdjbhis7imn0.apps.googleusercontent.com'

  // PROD
   const clientId = '776087772930-qsd7hqopmfh3fke0dukmm3kqiblnca4n.apps.googleusercontent.com'

  const onSuccess = (res: any) => {
    const { tokenId: id_token, accessToken: access_token } = res;
    setGoogleRes({ id_token, access_token });
  };
  const onFailure = (err: any) => {
    dispatch(triggerErr(err.error));
  };

  React.useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: '',
      });
    };
    gapi.load('client:auth2', initClient);
  }, []);

  const [login, { loading, error: loginErr }] = useMutation(LOGIN, {
    variables: {
      email: loginForm.email,
      password: loginForm.password,
    },
    onCompleted: (data) => {
      if (data.login.status === 'LOCKED') {
        dispatch(userLogout());
        navigate('/login', { replace: true });
        return;
      }
      if (data?.login?.roles?.includes('ADMIN')) {
        dispatch(userLogin(data?.login));
      }
      navigate('/transactions', { replace: true });
    },
  });

  React.useEffect(() => {
    if (loginErr?.message) {
      dispatch(triggerErr(loginErr?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginErr]);

  const onSubmit = async () => {
    try {
      await login();
    } catch (err) { }
  };

  const onInputValue = (e: any) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    if (googleRes.access_token === '' || googleRes.id_token === '') return;
    googleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleRes]);

  const [googleLogin, { loading: googleLoginLoader }] = useMutation(
    GOOGLE_LOGIN,
    {
      variables: {
        access_token: googleRes.access_token,
        id_token: googleRes.id_token,
      },
      onCompleted: (data) => {
        dispatch(userLogin(data.googleLogin));
        navigate('/transactions', { replace: true });
        return;
      },
    }
  );

  return (
    <section className="login">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <form className="login__form">
          <label className="login__form-title">Log In</label>
          <div className="login__form-body">
            <div className="login__form-body-line">
              <input
                onChange={onInputValue}
                type="email"
                name="email"
                value={loginForm.email}
                className={`input ${loginForm?.email === '' ? 'error' : ''}`}
                placeholder="Email"
                autoComplete="off"
                minLength={3}
              />
              {loginForm?.email === '' && (
                <span className="error-color">
                  <p>Field is required!</p>
                </span>
              )}
            </div>
            <div className="login__form-body-line">
              <input
                onChange={onInputValue}
                type="password"
                name="password"
                value={loginForm.password}
                className={`input ${loginForm?.password === '' ? 'error' : ''}`}
                placeholder="Password"
                autoComplete="off"
                minLength={3}
              />
              {loginForm?.password === '' && (
                <span className="error-color">
                  <p>Field is required!</p>
                </span>
              )}
            </div>
            <button
              type={'button'}
              className="btn btn-login"
              onClick={onSubmit}
            >
              {loading ? <Loader size={30} /> : 'Login'}
            </button>

            <div
              style={{
                marginTop: 24,
              }}
            >
              <h2 className="or-line">
                <span
                  style={{
                    background: '#fff',
                    padding: '0 12px',
                  }}
                >
                  OR
                </span>
              </h2>
            </div>

            <div className="login__google-login">
              {googleLoginLoader ? (
                <Loader />
              ) : (
                <GoogleLogin
                  clientId={clientId}
                  buttonText="Sign in with Google"
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  cookiePolicy={'single_host_origin'}
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Login;
