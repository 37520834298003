export type IdologyType = {
    userId: string;
}

export type AnswerType = "IQ" | "CHALLENGE" | "DIFFERENTIATOR" | null;;

export enum IdologyStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCEED = 'SUCCEED',
    FAILED = 'FAILED',
    CANCELLED = 'CANCELLED'
}

export const IdologyStatusObj = {
    [IdologyStatus.IN_PROGRESS]: "IN PROGRESS",
    [IdologyStatus.SUCCEED]: IdologyStatus.SUCCEED,
    [IdologyStatus.FAILED]: IdologyStatus.FAILED,
    [IdologyStatus.CANCELLED]: IdologyStatus.CANCELLED
}

export enum UserStatus {
    PENDING = "PENDING",
    VERIFYING = "VERIFYING",
    PREACTIVE = "PREACTIVE",
    ACTIVE = "ACTIVE",
    LOCKED = "LOCKED",
    DELETED = "DELETED",
}

export const userErrorStatuses = [UserStatus.LOCKED, UserStatus.DELETED]

export interface IdologyQuestion {
    order: number;
    prompt: string;
    answers: string[];
    type: string;
    __typename: string
}

export interface IdologyRes {
    questions: IdologyQuestion[];
    status: IdologyStatus | null;
    answerType: AnswerType;
    __typename: string
}

export interface IdologyAnswer {
    order: number;
    type: string;
    answer: any;
}

export interface IdologyAnsweredResponse {
    idologyResponse: {
        answerType: AnswerType;
        idScan: Boolean;
        idScanUrl: String;
        questions: IdologyQuestion[];
        status: IdologyStatus;
    };
    uuid: string; // userUUID
    status: UserStatus
}

export const questionBlockStyle = {
    marginBottom: 50
}

export const buttonStyle = {
    background: '#28DAB6',
    borderRadius: 15,
    width: 450,
    height: 45,
    margin: '0 auto',
    border: 'none',
    color: '#fff',
    padding: 15,
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 600
}

export const IdologyAnsweredRes = {
    [IdologyStatus.FAILED]: { error: true, message: 'Failed! please try again!', newQ: false },
    [IdologyStatus.CANCELLED]: { error: true, message: 'Cancelled! Please try again', newQ: false },
    [IdologyStatus.SUCCEED]: { error: false, message: 'Succeeded!', newQ: false },
    [IdologyStatus.IN_PROGRESS]: { error: false, message: 'In progress! You have a new question to answer!', newQ: true },
}

export const ERROR_MSG_SECTION_ONE = "We're sorry, but we cannot find enough data on your profile, in order to complete the identity verification procedure."

export const ERROR_MSG_SECTION_TWO = "Please contact customer service with any further questions that you may have at 1-844-782-6763 or send us a message directly "

export const ERROR_MSG_SECTION_THREE = "https://esignatureguarantee.com/contact"