import { configureStore } from '@reduxjs/toolkit';
import errorHandlingSlice from './slices/error-handling/error-handling-slice';
import userSlice from './slices/user/user-slice';

export default configureStore({
  reducer: {
    user: userSlice,
    errors: errorHandlingSlice,
  },
});
