/* eslint-disable no-use-before-define */
import { gql } from '@apollo/client';

export const GET_TRANSACTION = gql`
  query getTransaction($transactionUUID: String!) {
    getTransaction(transactionUUID: $transactionUUID) {
      uuid
      code
      stockholderType
      format
      securityType
      createdAt
      status
      completedOn
      notes {
        uuid
        content
        createdAt
        author {
          firstName
          lastName
          email
        }
      }
      certificates {
        uuid
        symbol
        createdAt
        marketValueTotal
        price
        shares
        medallionsCount
        pendingMedallionsCount
        approvedMedallionsCount
        deniedMedallionsCount
      }
      notes {
        uuid
        content
        createdAt
        author {
          uuid
          firstName
          lastName
          email
          profilePicture
        }
      }
      transferAgent {
        uuid
        name
      }
      shippings {
        uuid
        type
        from {
          uuid
          addressLine1
          addressLine2
          state
          zip
          city
        }
        to {
          addressLine1
          addressLine2
          state
          zip
          city
        }
        type
        provider
        createdAt
      }
      discounts {
        code
        valueType
        value
      }
      charge {
        uuid
        status
        type
        provider
        totalDiscountsInCents
        totalMedallionCostInCents
        totalShippingCostInCents
        updatedAt
        usaePay {
          details
        }
      }
      totalRefundsInCents
      documents {
        uuid
        name
        status
        documentType {
          type
          signRequired
        }
      }
      user {
        uuid
        firstName
        lastName
        status
        email
        mobile {
          countryCode
          localNumber
        }
        home {
          uuid
        }
      }
    }
  }
`;
