/* eslint-disable no-use-before-define */
import { gql } from '@apollo/client';

// pagination { limit: 1000000, page: 1 }

export const LIST_USERS = gql`
  query listUsers($pagination: Pagination, $filter: ListUserFilter) {
    listUsers(pagination: $pagination, filter: $filter) {
      items {
        uuid
        firstName
        lastName
        email
        roles
        paymentMethod
        status
        createdAt
        mobile {
          uuid
          countryCode
          localNumber
          isVerified
        }
        home {
          uuid
          addressLine1
          addressLine2
          zip
          country
          state
        }
      }
      total
      hasMore
    }
  }
`;
