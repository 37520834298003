import { useLazyQuery, useMutation } from '@apollo/client';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SEND_IDOLOGY_ANSWERS } from '../../graphql/mutations/send-idology-answers.mutation';
import { GET_IDENTITY_QUESTIONS } from '../../graphql/queries/get-identity-questions.query';
import { triggerErr, triggerSuccess } from '../../redux/slices/error-handling/error-handling-slice';
import Loader from '../loader/loader.component';
import { AnswerType, buttonStyle, ERROR_MSG_SECTION_ONE, ERROR_MSG_SECTION_THREE, ERROR_MSG_SECTION_TWO, IdologyAnswer, IdologyAnsweredRes, IdologyAnsweredResponse, IdologyQuestion, IdologyRes, IdologyType, questionBlockStyle, userErrorStatuses, UserStatus } from './idology-exam.constants';

const IdologyExam: React.FC<IdologyType> = ({ userId }) => {

    const [questions, setQuestions] = useState<IdologyQuestion[]>([]);
    const [answers, setAnswers] = useState<IdologyAnswer[]>([]);
    const [answerType, setAnswerType] = useState<AnswerType>('IQ');
    const [finalMessage, setFinalMessage] = useState<string>('');

    const dispatch = useDispatch();

    const [getInitialQuestions, { loading, error: getQuestionsError }] =
        useLazyQuery(GET_IDENTITY_QUESTIONS, {
            variables: {
                userUUID: userId
            },
            fetchPolicy: 'no-cache',
            onCompleted: (d: { getIdentityQuestions: IdologyRes }) => {
                if (d?.getIdentityQuestions && d?.getIdentityQuestions?.questions?.length) {
                    const questions = d?.getIdentityQuestions?.questions;
                    setQuestions(questions);
                    setAnswerType(d?.getIdentityQuestions?.answerType);
                }
            },
        });

    const [answerQuestions, { loading: answerLoader, error: answerQuestionsError }] =
        useMutation(SEND_IDOLOGY_ANSWERS, {
            variables: {
                userUUID: userId,
                type: answerType,
                answers: answers
            },
            fetchPolicy: 'no-cache',
            onCompleted: (d: { sendIdentityAnswers: IdologyAnsweredResponse }) => {
                const idologyData = d?.sendIdentityAnswers?.idologyResponse;
                if (idologyData) {
                    const questions = idologyData.questions;
                    const answerType = idologyData.answerType;
                    const userStatus = d?.sendIdentityAnswers?.status;
                    const { error, message, newQ } = IdologyAnsweredRes[idologyData.status];
                    manageAnsweredRes(error, message, newQ, questions, answerType, userStatus);
                } else {
                    manageAnsweredRes(true, 'Failed!', false, [], null);
                }
            },
        });

    React.useEffect(() => {
        if (!userId) return;

        setFinalMessage('');
        getInitialQuestions();
    }, [userId]);

    React.useEffect(() => {
        if (getQuestionsError?.message) {
            dispatch(triggerErr(getQuestionsError?.message));
            setFinalMessage(getQuestionsError?.message);
        }
        if (answerQuestionsError?.message) {
            dispatch(triggerErr(answerQuestionsError?.message));
            setFinalMessage(answerQuestionsError?.message);
        }
    }, [getQuestionsError, answerQuestionsError])

    const onAnswer = (e: IdologyAnswer) => {
        if (!e.order) {
            setAnswers([{ ...e, order: 1 }]);
            return;
        }

        let isAnswer = answers.filter((a) => a.order !== e.order);

        if (isAnswer) {
            setAnswers(
                [...isAnswer, e].sort(
                    (a: IdologyAnswer, b: IdologyAnswer) => a.order - b.order
                )
            );
        }
    };

    const onsubmit = () => {
        if (answers?.length !== questions?.length) return;
        setFinalMessage('');
        answerQuestions();
    };

    const manageAnsweredRes = (error: boolean, message: string, newQ: boolean, questions: IdologyQuestion[], answerType: AnswerType, userStatus?: UserStatus) => {
        if (userStatus && userErrorStatuses.includes(userStatus)) {
            dispatch(triggerErr('User is ' + userStatus + '!'));
            setFinalMessage('Failed!' + 'User is ' + userStatus + '!');
            return;
        }
        if (answerType === null) {
            dispatch(triggerErr('Something is wrong!'));
            setFinalMessage('Failed! Please try again later!');
            return;
        }
        if (newQ) {
            dispatch(triggerErr(message));
            setQuestions(questions);
            setAnswerType(answerType);
            return;
        }
        if (error) {
            dispatch(triggerErr(message));
            setQuestions([]);
            setFinalMessage('Failed to answer idology question!');
            return;
        }
        if (!error && !newQ) {
            dispatch(triggerSuccess(message));
            setQuestions([]);
            setFinalMessage(message);
        }
    };

    const errorEmptyArrays = <div style={{
        maxWidth: 600,
        display: "flex",
        flexDirection: "column",
    }}>
        <div>{ERROR_MSG_SECTION_ONE}</div>
        <div style={{ marginTop: 15 }}>{ERROR_MSG_SECTION_TWO} <a href={ERROR_MSG_SECTION_THREE}>{ERROR_MSG_SECTION_THREE}</a></div>
    </div>;

    const emptyQuestionStatus = <div style={{ textAlign: 'center', fontSize: 18 }}>{finalMessage || errorEmptyArrays}</div>;

    const questionsDiv = <>
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "10px 0",
            }}
        >
            {questions?.map((q: IdologyQuestion) => (
                <div key={q?.order} style={questionBlockStyle}>
                    <label><b>{q?.prompt}</b></label>
                    <RadioGroup
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onAnswer({
                                order: q?.order,
                                type: q?.type,
                                answer: e?.target?.value,
                            })
                        }
                        style={{ fontSize: 14 }}
                    >
                        {q?.answers?.map((v: string) => {
                            return (
                                <FormControlLabel
                                    value={v}
                                    control={<Radio size="small" color='primary' />}
                                    label={v}
                                    key={v}
                                    style={{ marginTop: 15 }}
                                />
                            );
                        })}
                    </RadioGroup>
                </div>
            ))}
            {finalMessage.trim() !== '' ? finalMessage : <>
                {questions?.length && questions[0].type !== null ? <button disabled={loading || answerLoader || questions.length !== answers.length} className="btn btn-login" type="button" onClick={onsubmit} style={buttonStyle}>
                    {answerLoader || loading ? <Loader size={20} /> : 'Continue'}
                </button> : ''}
            </>}
        </div>
    </>
    return <>{answerLoader || loading ? <Loader /> : <div>
        {questions?.length && questions[0].type !== null ? questionsDiv : emptyQuestionStatus}
    </div>}</>
};

export default IdologyExam;