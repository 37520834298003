/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

// paintaion: {
//     limit: 10
//     page: 1
// }
export const LIST_MEDALLIONS = gql`
query listMedallions(
    $certificateUUID: String!
    $transactionUUID: String!
    $pagination: Pagination
    $completed: Boolean) {
        listMedallions(
            transactionUUID: $transactionUUID
            certificateUUID: $certificateUUID
            pagination: $pagination
            completed: $completed
        ) {
            items {
                uuid
                marketValue
                status
                included
                costInCents
                createdAt
            }
            hasMore
            total
	}
}
`