import { gql } from '@apollo/client';

export const GET_REPORTS = gql`
  query getReportResults(
    $reportUUID: String!
    $dates: DateRange
    $pagination: Pagination
  ) {
    getReportResults(
      reportUUID: $reportUUID
      pagination: $pagination
      dates: $dates
    ) {
      items
      total
      hasMore
    }
  }
`;
