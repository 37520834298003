/* eslint-disable no-use-before-define */
import { gql } from '@apollo/client';

export const GET_FEDEX_SERVICES = gql`
  query {
    getFedexServices {
      uuid
      name
      fedexEnumCode
    }
  }
`;
