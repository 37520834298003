export interface Discount {
    code: string;
    value: number
    valueType: discountType
}

export type discountType = 'PERCENTAGE' | 'AMOUNT_CENTS'

export const translateDiscount = (discountType: discountType, value: number) => {
    if (discountType === 'PERCENTAGE') return `${value}%`;
    return `${(value / 100).toFixed(2)}$`
}