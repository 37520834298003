import React from 'react';
import { useLocation } from 'react-router-dom';
import { normelizeTitle, removeDashes, titleDesc } from './page-title.helper';

import './page-title.scss';

const PageTitle = () => {
  const location = useLocation();

  let pathName = location.pathname.split('/')[1];

  return (
    <div className="page-title">
      {removeDashes(normelizeTitle(pathName))}
      <p className="page-title__desc">{titleDesc[normelizeTitle(pathName)]}</p>
      <hr className="page-title__hr"></hr>
    </div>
  );
};

export default PageTitle;
