import { gql } from '@apollo/client';

export const UPDATE_USER_NAME = gql`
  mutation updateUserName(
    $userUUID: String!
    $firstName: String!
    $lastName: String!
  ) {
    updateUserName(
      userUUID: $userUUID
      firstName: $firstName
      lastName: $lastName
    ) {
      uuid
      firstName
      lastName
      email
      roles
      paymentMethod
      status
      dayOfBirth
      createdAt
      mobile {
        uuid
        countryCode
        localNumber
        isVerified
      }
      home {
        uuid
        addressLine1
        addressLine2
        zip
        country
        state
      }
      idologyLogs {
        uuid
        idNumber
        requestResponse
        status
      }
    }
  }
`;
