import React, { useState } from 'react';
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import './user-details.scss';
import moment from 'moment';

type userDetailsType = {
  user: any;
};

const UserDetails: React.FC<userDetailsType> = ({ user }) => {
  const parsedDate = moment(user?.dayOfBirth, 'YYYY-MM-DD', true);
  const [bDate, setBdate] = useState<any>('');
  const notExist = <label className="user-details__green-label">-</label>;

  React.useEffect(() => {
    if (!user?.dayOfBirth) return;
    const date = moment(user?.dayOfBirth);
    setBdate(date.format('MM/DD/YYYY'));
  }, [user])

  return (
    <div className="user-details">
      <div className="user-details__col">
        <label className="user-details__title">User name</label>
        {user?.firstName || user?.lastName ? (
          <label className="user-details__green-label">
            {user?.firstName} {user?.lastName}
          </label>
        ) : (
          notExist
        )}
        <label className="user-details__title">Email</label>
        {user?.email ? (
          <label className="user-details__green-label" style={{ display: "flex", alignItems: "center", gap: 15 }}>
            <span>{user?.email}</span>
            <span>{user?.isEmailVerified ? <CheckCircleOutlinedIcon color="success" /> : <ErrorOutlineOutlinedIcon color="error" />}</span>
          </label>
        ) : (
          notExist
        )}
        <label className="user-details__title">Cell phone</label>
        {user?.mobile?.countryCode ? (
          <label className="user-details__green-label" style={{ display: "flex", alignItems: "center", gap: 15 }}>
            <span>+{user?.mobile?.countryCode}-{user?.mobile?.localNumber}</span>
            <span>{user?.mobile?.isVerified ? <CheckCircleOutlinedIcon color="success" /> : <ErrorOutlineOutlinedIcon color="error" />}</span>
          </label>
        ) : (
          notExist
        )}
        <label className="user-details__title">Birthday</label>
        <label className="user-details__green-label">{user?.dayOfBirth && parsedDate?.isValid() ? bDate : notExist}</label>
      </div>

      <div className="user-details__col">
        <label className="user-details__title">Home address</label>
        {user?.home?.addressLine1 ? (
          <label className="user-details__green-label">
            <div>{user?.home?.addressLine1}</div>
            <div>{user?.home?.city}, {user?.home?.state}, {user?.home?.zip}</div>
          </label>

        ) : (
          notExist
        )}
      </div>

      <div className="user-details__col">
        <label className="user-details__title">Status</label>
        <label className="user-details__green-label">{user?.status}</label>
      </div>
    </div>
  );
};

export default UserDetails;
