import { gql } from '@apollo/client';

export const CHANGE_MEDALLIONS_STATUS = gql`
  mutation changeMedallionsStatus(
    $status: MedallionStatus!
    $medallionUUIDs: [String!]!
    $transactionUUID: String!
  ) {
    changeMedallionsStatus(
      transactionUUID: $transactionUUID
      medallionUUIDs: $medallionUUIDs
      status: $status
    ) {
      uuid
      costInCents
      discountInCents
      marketValue
      status
      included
    }
  }
`;
