import { useQuery, useLazyQuery } from '@apollo/client';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';

import { LIST_REPORTS } from '../../graphql/queries/list-reports.query';
import { addLeadingZeros } from '../../shared/helpers/leading-zero';
import Loader from '../loader/loader.component';
import PageTitle from '../page-title/page-title.component';
import { GET_REPORTS } from '../../graphql/queries/get-reports.query';

import './reports.scss';
import Table, { ButtonActionType } from '../table/table.component';
import { tableReportsTitles } from './reports.helper';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GENERATE_CSV_REPORT } from '../../graphql/queries/generate-csv-report.query';
import { triggerErr, triggerSuccess } from '../../redux/slices/error-handling/error-handling-slice';
import { GET_ME } from '../../graphql/queries/get-me.query';
import { userLogin, userLogout } from '../../redux/slices/user/user-slice';

const Reports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getMe, { loading: getMeLoader }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.getMe?.email) {
        dispatch(userLogin(data?.getMe));
        return;
      } else {
        dispatch(userLogout());
        navigate('/login', { replace: true });
      }
    },
  });

  React.useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const date = new Date();
  const year = date.getFullYear();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const today = `${year}-${addLeadingZeros(month, 2)}-${addLeadingZeros(
    day,
    2
  )}`;

  const [reportUUID, setRreportUUID] = useState('');
  const [reportsDate, setReportsDate] = useState({
    startDate: today,
    endDate: today,
  });
  const [reportsList, setReportsList] = useState([]);
  const [isDatesCorrect, setIsDatesCorrect] = useState(true);
  const [page, setPage] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);
  const [isBackPage, setIsBackPage] = useState(false);
  const [titles, setTitles] = useState([]);

  //actiate pagination (table buttons)
  const onTableButtonsClicked = (action: ButtonActionType) => {
    let pageNumber = 0;
    if (action === ButtonActionType.BACK) {
      pageNumber = page - 1;
    }
    if (action === ButtonActionType.NEXT) {
      pageNumber = page + 1;
    }
    setPage(pageNumber);
  };

  React.useEffect(() => {
    if (page === 0) return;
    if (!reports.length) return;
    getReportsFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const { error: grlError } = useQuery(LIST_REPORTS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setReportsList(data?.listReports);
    },
  });

  const onValueChanged = (e: any) => setRreportUUID(e?.target?.value);

  const onSearchReports = () => {
    if (!isDatesCorrect || reportUUID === '') return;
    getReportsFunc();
  };

  const onDateValueChanged = (e: any) => {
    setReportsDate({
      ...reportsDate,
      [e?.target?.name]: e?.target?.value,
    });
  };

  React.useEffect(() => {
    let date1Updated = new Date(reportsDate.startDate.replace(/-/g, '/'));
    let date2Updated = new Date(reportsDate.endDate.replace(/-/g, '/'));
    setIsDatesCorrect(date1Updated <= date2Updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsDate]);

  const [reports, setReports] = useState<any>([]);

  const [
    getReportsFunc,
    { loading: getReportsLoading, error: getReportsError },
  ] = useLazyQuery(GET_REPORTS, {
    variables: {
      dates: {
        startDate: reportsDate.startDate,
        endDate: reportsDate.endDate,
      },
      reportUUID: reportUUID,
      pagination: {
        page: page,
        limit: 10,
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const reports = data?.getReportResults?.items;
      setReports(data?.getReportResults);
      if (reports && reports?.length) {
        setTitles(Object.keys(reports[0]) as any);
      }
      setIsNextPage(data?.getReportResults?.hasMore);
      setIsBackPage(page > 1);
    },
  });

  const [getReportsCSVFunc, { loading: getReportsCSVLoading, error: grError }] =
    useLazyQuery(GENERATE_CSV_REPORT, {
      variables: {
        dates: {
          startDate: reportsDate.startDate,
          endDate: reportsDate.endDate,
        },
        reportUUID: reportUUID
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.generateCSVReport) {
          dispatch(triggerSuccess(data?.generateCSVReport))
        }
      },
    });

  React.useEffect(() => {
    if (grError?.message) {
      dispatch(triggerErr(grError?.message));
    }
    if (getReportsError?.message) {
      dispatch(triggerErr(getReportsError?.message));
    }
    if (grlError?.message) {
      dispatch(triggerErr(grlError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grError, getReportsError, grlError]);

  const tableBody = reports?.items?.map((r: any, i: number) => {
    const keys = reports?.items?.length && Object.keys(reports?.items[0]);
    return <tr className='reports__table-body' key={i}>{keys?.map((k: any) => {
      return <td className="medallions__table-td">
        <span className="medallions__td-body">{r[k]}</span>
      </td>
    })}
    </tr>
  });

  return getMeLoader ? (
    <Loader />
  ) : (
    <div className="reports right-panel-body">
      <PageTitle />
      <div style={{ marginTop: 48 }}>
        <FormControl
          sx={{
            width: 200,
          }}
        >
          <InputLabel id="reports-dropdown">Select report</InputLabel>
          <Select
            labelId="reports-dropdown"
            id="reports-select"
            value={reportUUID}
            label="Select report"
            onChange={onValueChanged}
            name="valueType"
            style={{ width: '100%' }}
            className={`${reportUUID === '' ? 'error' : ''}`}
          >
            {reportsList?.map((v: any, i: number) => (
              <MenuItem value={v?.uuid} key={v?.uuid}>
                {v?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {reportUUID !== '' ? (
          <>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: 48,
              }}
            >
              <input
                onChange={onDateValueChanged}
                type={'date'}
                value={reportsDate.startDate}
                name="startDate"
                className={`input ${reportsDate.startDate === '' ? 'error' : ''
                  }`}
                style={{ flex: 1, maxWidth: 350, marginRight: 25 }}
                disabled={reportUUID === ''}
              />
              <input
                onChange={onDateValueChanged}
                type={'date'}
                value={reportsDate.endDate}
                name="endDate"
                className={`input ${reportsDate.startDate === '' ? 'error' : ''
                  }`}
                style={{ flex: 1, maxWidth: 350 }}
                disabled={reportUUID === ''}
              />
            </div>
            {!isDatesCorrect && (
              <span className="error-color d-block">
                Please choose valid dates!
              </span>
            )}
            <button
              className="reports__btn-approve"
              onClick={onSearchReports}
              disabled={reportUUID === '' || !isDatesCorrect}
              style={{
                background:
                  reportUUID === '' || !isDatesCorrect ? '' : '#28DAB6',
                color: '#fff',
              }}
            >
              Search
            </button>
          </>
        ) : (
          ''
        )}
      </div>
      <div>
        {getReportsLoading ? (
          <Loader />
        ) : (
          <div style={{ marginTop: 48 }}>
            {reports?.items?.length ? (
              <button
                onClick={async () => await getReportsCSVFunc()}
                className="reports__csv-btn"
              >
                {getReportsCSVLoading ? <Loader size={20} /> : 'Download CSV'}
              </button>
            ) : (
              ''
            )}
            <Table
              titles={titles}
              onButtonClicked={onTableButtonsClicked}
              isNextPage={isNextPage}
              isBackPage={isBackPage}
            >
              {tableBody}
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Reports;
