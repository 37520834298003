export const normelizeTitle = (title: string) => {
  return title.charAt(0).toUpperCase() + title.slice(1);
};

export const titleDesc: { [key: string]: string } = {
  Transactions: 'Track and manage available transactions',
  Users: 'Manage and edit users',
  'Discount-codes': 'Manage and edit discount Codes',
  Surveys: 'Serveys details',
  Reports: 'Reports details',
};

export const removeDashes = (title: string) => {
  return title.replaceAll('-', ' ');
};
