import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { LIST_TRANSACTIONS } from '../../graphql/queries/list-transactions.query';
import Loader from '../loader/loader.component';
import Table, { ButtonActionType } from '../table/table.component';
import { tableTransactionsTitles } from '../transactions/transactions.helper';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import { useDispatch } from 'react-redux';

const UserTransactions = () => {
  const [filter, setFilter] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [userUUID, setUserUUID] = useState<any>('');
  const [page, setPage] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);
  const [isBackPage, setIsBackPage] = useState(false);
  const dispatch = useDispatch();

  //actiate pagination (table buttons)
  const onTableButtonsClicked = (action: ButtonActionType) => {
    let pageNumber = 0;
    if (action === ButtonActionType.BACK) {
      pageNumber = page - 1;
    }
    if (action === ButtonActionType.NEXT) {
      pageNumber = page + 1;
    }
    setPage(pageNumber);
  };

  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    let arr = location.pathname.split('/');
    const uuid = arr[arr?.length - 1];
    setUserUUID(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    if (userUUID === '') return;
    if (page === 0) return;
    getUserFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page]);

  React.useEffect(() => {
    if (userUUID === '') return;
    setFilter({ ...filter, userUUID: userUUID });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUUID]);

  const [getUserFunc, { loading: getUserLoader, error: guError }] =
    useLazyQuery(LIST_TRANSACTIONS, {
      variables: {
        pagination: { limit: 10, page: page },
        filter: filter,
        sort: { updatedAt: 'DESC', createdAt: 'DESC' },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        setTransactions(data?.listTransactions?.items);
        setIsNextPage(data?.listTransactions?.hasMore);
        setIsBackPage(page > 1);
      },
    });

  React.useEffect(() => {
    if (guError?.message) {
      dispatch(triggerErr(guError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guError]);

  const tableBody = transactions?.map((t: any) => (
    <tr key={t?.uuid}>
      <td>
        <span
          className="table__transaction-id"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(`/transaction/${t?.uuid}`, { replace: true });
          }}
        >
          {t?.code}
        </span>
      </td>
      <td>
        <span className="table__body-td">{t?.certificates?.length}</span>
      </td>
      <td>
        <span className="table__body-td">
          {t?.certificates?.reduce((acc: number, t: any) => {
            return acc + t?.medallionsCount;
          }, 0)}
        </span>
      </td>
      <td>
        <span className="table__body-td">
          $
          {(
            (t?.charge?.totalMedallionCostInCents +
              t?.charge?.totalShippingCostInCents) /
            100
          ).toFixed(2)}
        </span>
      </td>
      <td className="table__body-transaction-id">
        <span className="table__body-td">
          {moment(t?.createdAt).format('LL')}
        </span>
        <span className="table__issuer-name">
          {moment(t?.createdAt).format('LTS')}
        </span>
      </td>
      <td>
        <span className="table__body-td">
          {t?.status.charAt(0).toUpperCase() + t?.status.slice(1).toLowerCase()}
        </span>
      </td>
    </tr>
  ));
  return (
    <div>
      {getUserLoader ? (
        <Loader />
      ) : (
        <div style={{ marginTop: 48 }}>
          <Table
            titles={tableTransactionsTitles}
            onButtonClicked={onTableButtonsClicked}
            isNextPage={isNextPage}
            isBackPage={isBackPage}
          >
            {tableBody}
          </Table>
        </div>
      )}
    </div>
  );
};

export default UserTransactions;
