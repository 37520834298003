import React, { Dispatch, SetStateAction } from 'react';
import Select from 'react-select';

type AdjustedAddressesType = {
  setAdjusted: Dispatch<
    SetStateAction<{
      addressLine1: string;
      addressLine2: string;
      city: string;
      state: string;
      zip: string;
      name: string;
    }>
  >;
  adjustedAddresses: any[];
};

const AdjustedAdressesDropdown: React.FC<AdjustedAddressesType> = ({
  setAdjusted,
  adjustedAddresses,
}) => {
  const onAddressSelected = (id: string) => {
    let chosenAddress = adjustedAddresses?.find(
      (a: any) => a?.uuid === id
    );
    chosenAddress = {
      addressLine1: chosenAddress?.addressLine1 || '',
      addressLine2: chosenAddress?.addressLine2 || '',
      city: chosenAddress?.city || '',
      state: chosenAddress?.state || '',
      zip: chosenAddress?.zip || '',
    };
    setAdjusted(chosenAddress);
  };

  return (
    <Select
      className="basic-single"
      styles={{
        control: styles => ({ ...styles, minWidth: 350, height: 48 })
      }}
      onChange={data => onAddressSelected(data?.value)}
      classNamePrefix="select"
      isClearable={true}
      isSearchable={true}
      name="color"
      options={adjustedAddresses?.filter((a: any) => a?.name)
        .sort((a: any, b: any) => a?.name?.toUpperCase().localeCompare(b?.name?.toUpperCase()))
        .map(a => ({ value: a?.uuid, label: a?.name?.toUpperCase() }))}
    />
  );
};

export default AdjustedAdressesDropdown;
