import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { LIST_VERIFICATION_CODES } from '../../graphql/queries/list-verification-codes.query';
import Table, { ButtonActionType } from '../table/table.component';
import { tableTransactionVerificationsTitles } from './transaction-verifications.helper';
import moment from 'moment';

import './transaction-verifications.scss';
import Loader from '../loader/loader.component';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import { useDispatch } from 'react-redux';

type TransactionVerificationsType = {
  transactionUUID: string;
};

const TransactionVerifications: React.FC<TransactionVerificationsType> = ({
  transactionUUID,
}) => {
  const [verifications, setVerifications] = useState([]);
  const [page, setPage] = useState(1);
  const [isNextPage, setIsNextPage] = useState(false);
  const [isBackPage, setIsBackPage] = useState(false);
  const dispatch = useDispatch();

  //actiate pagination (table buttons)
  const onTableButtonsClicked = (action: ButtonActionType) => {
    let pageNumber = 0;
    if (action === ButtonActionType.BACK) {
      pageNumber = page - 1;
    }
    if (action === ButtonActionType.NEXT) {
      pageNumber = page + 1;
    }
    setPage(pageNumber);
  };

  const [
    fetchVerifications,
    { loading: getVerificationsLoader, error: fvError },
  ] = useLazyQuery(LIST_VERIFICATION_CODES, {
    variables: {
      transactionUUID: transactionUUID,
      page: page,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setVerifications(data?.listVerificationCodesSent?.items);
      setIsNextPage(data?.hasMore);
      setIsBackPage(page > 1);
    },
  });

  React.useEffect(() => {
    if (fvError?.message) {
      dispatch(triggerErr(fvError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fvError]);

  React.useEffect(() => {
    if (transactionUUID === '') return;
    if (page <= 0) return;

    fetchVerifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionUUID, page]);

  const verificationsTableBody = verifications?.map((v: any) => (
    <tr>
      <td className="table__body-transaction-id">
        <span className="table__body-td">{moment(v?.sentAt).format('LL')}</span>
        <span className="table__issuer-name">
          {moment(v?.sentAt).format('LTS')}
        </span>
      </td>
      <td className="transaction-verifications__table-td">
        <span className="transaction-verifications__td-body">{v?.status}</span>
      </td>
      <td className="transaction-verifications__table-td">
        <span className="transaction-verifications__td-body">
          {v?.phoneNumber}
        </span>
      </td>
      <td className="transaction-verifications__table-td">
        <span className="transaction-verifications__td-body">
          {v?.carrier?.name}
        </span>
      </td>
    </tr>
  ));
  return (
    <>
      {getVerificationsLoader ? (
        <Loader />
      ) : (
        <div className="transaction-verifications">
          <Table
            titles={tableTransactionVerificationsTitles}
            onButtonClicked={onTableButtonsClicked}
            isNextPage={isNextPage}
            isBackPage={isBackPage}
            displayButtons={false}
          >
            {verificationsTableBody}
          </Table>
        </div>
      )}
    </>
  );
};

export default TransactionVerifications;
