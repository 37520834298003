import { gql } from '@apollo/client';

export const ROLLBACK_SUSPICIOUS_TRANSACTION = gql`
  mutation rollbackSuspiciousTransaction($transactionUUID: String!) {
    rollbackSuspiciousTransaction(transactionUUID: $transactionUUID) {
      uuid
      code
      status
    }
  }
`;
