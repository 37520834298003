import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { certificatesHeaders } from './medallions.helper';
import './medallions.scss';

type CertificateComponentType = {
    certificates: any[];
    onCertificateClicked: (c: any) => void;
}
const Certificates: React.FC<CertificateComponentType> = ({ certificates, onCertificateClicked }) => {
    return <div style={{ maxWidth: 1200 }}>
        <div style={{ marginBottom: 24 }}>
            <h4>List of certificates</h4>
        </div>
        <div className='medallions__certificates-headers'>
            {certificatesHeaders.map((header: string) => <div style={{ flex: 1 }}>{header}</div>)}
        </div>
        {certificates.map((certificate: any, i: number) =>
            <div onClick={() => onCertificateClicked(certificate)}
                className='medallions__certificates-headers-body'
                key={i}>
                <div style={{ flex: 1 }}>{certificate?.symbol}</div>
                <div style={{ flex: 1 }}>{certificate?.shares}</div>
                <div style={{ flex: 1 }}>${certificate?.price}</div>
                <div style={{ flex: 1 }}>{certificate?.pendingMedallionsCount}</div>
                <div style={{ flex: 1 }}>{certificate?.deniedMedallionsCount}</div>
                <div style={{ flex: 1 }}>{certificate?.approvedMedallionsCount}</div>
                <span style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "flex-end"
                }}>
                    <ArrowForwardIcon />
                </span>
            </div>)}
    </div>
};

export default Certificates;