import { useLazyQuery, useMutation } from '@apollo/client';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import Navigator from '../navigator/navigator.component';
import {
  discountsNavigatorDetails,
  rulesToAdd,
} from './create-discount.helper';
import { CREATE_DISCOUNT } from '../../graphql/mutations/create-discount.mutation';

import './create-discount.scss';
import { useNavigate } from 'react-router-dom';
import Loader from '../loader/loader.component';
import UsersDropdown from '../users-dropdown/users-dropdown.component';
import { useDispatch } from 'react-redux';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import { userLogin, userLogout } from '../../redux/slices/user/user-slice';
import { GET_ME } from '../../graphql/queries/get-me.query';

const CreateDiscount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getMe, { loading: getMeLoader }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.getMe?.email) {
        dispatch(userLogin(data?.getMe));
        return;
      } else {
        dispatch(userLogout());
        navigate('/login', { replace: true });
      }
    },
  });

  React.useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [newDiscount, setNewDiscount] = useState<{
    code: string;
    valueType: string;
    value: string;
    expireOn?: string;
    maxCount?: string;
    ticker?: string;
    userUUID?: string;
  }>({
    code: '',
    expireOn: '',
    maxCount: '',
    valueType: '',
    ticker: '',
    value: '',
    userUUID: '',
  });
  const [rules, setDiscountRules] = useState<string[]>([]);
  const [isReady, setIsReady] = useState<boolean>(false);

  const onValueChanged = (e: any) => {
    setNewDiscount({ ...newDiscount, [e.target.name]: e.target.value });
  };

  const addRules = () => {
    const rulesArr = rulesToAdd(newDiscount);
    if (rulesArr.length === 0) return;
    setDiscountRules(rulesArr);
  };

  const errorMessage = (
    <div style={{ color: 'red', marginBottom: 15 }}>Field is required!</div>
  );
  const secondErrorMessage = (
    <div style={{ color: 'red', marginBottom: 15 }}>
      At least one is required!
    </div>
  );

  const isReadyToCreate = () => {
    return (
      newDiscount.code !== '' &&
      newDiscount.value !== '' &&
      newDiscount.valueType !== '' &&
      !secondSectionIsEmpty()
    );
  };

  const createDiscount = () => {
    if (!isReadyToCreate()) return;
    addRules();
  };

  React.useEffect(() => {
    if (!rules.length) return;
    preparePayload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules]);

  const preparePayload = () => {
    setIsReady(false);
    if (newDiscount.expireOn === '') {
      delete newDiscount.expireOn;
    }
    if (newDiscount.maxCount === '') {
      delete newDiscount.maxCount;
    }
    if (newDiscount.ticker === '') {
      delete newDiscount.ticker;
    }
    if (newDiscount.userUUID === '') {
      delete newDiscount.userUUID;
    }
    setIsReady(true);
  };

  React.useEffect(() => {
    if (!isReady) return;
    createDiscountFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  const [
    createDiscountFunc,
    { loading: createDiscountLoader, error: cdError },
  ] = useMutation(CREATE_DISCOUNT, {
    variables: {
      input: {
        code: newDiscount.code,
        expireOn: newDiscount.expireOn,
        rules: rules,
        ticker: newDiscount.ticker,
        userUUID: newDiscount.userUUID, // how
        value: +newDiscount.value,
        maxCount: Number(newDiscount.maxCount),
        valueType: newDiscount.valueType,
      },
    },
    onCompleted: () => navigate('/discount-codes', { replace: true }),
  });

  React.useEffect(() => {
    if (cdError?.message) {
      dispatch(triggerErr(cdError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cdError]);

  const secondSectionIsEmpty = () => {
    return (
      newDiscount.maxCount === '' &&
      newDiscount.ticker === '' &&
      newDiscount.expireOn === '' &&
      newDiscount.userUUID === ''
    );
  };

  return getMeLoader ? (
    <Loader />
  ) : (
    <div className="create-discount right-panel-body">
      <Navigator tabs={discountsNavigatorDetails} />
      <div className="create-discount__form">
        <input
          onChange={onValueChanged}
          value={newDiscount.code}
          name="code"
          className={`input uppercase ${
            newDiscount.code === '' ? 'error' : ''
          }`}
          style={{ maxWidth: '100%' }}
          placeholder="Code"
        />
        {newDiscount.code === '' && errorMessage}
        <input
          onChange={onValueChanged}
          value={newDiscount.value}
          name="value"
          className={`input ${newDiscount.value === '' ? 'error' : ''}`}
          style={{ maxWidth: '100%' }}
          placeholder="Amount"
          type="number"
        />
        {newDiscount.value === '' && errorMessage}
        <FormControl>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={newDiscount.valueType}
            label="Type"
            onChange={onValueChanged}
            name="valueType"
            style={{ width: '100%' }}
            className={`${newDiscount.valueType === '' ? 'error' : ''}`}
          >
            {['PERCENTAGE', 'AMOUNT_CENTS'].map((v: any, i: number) => (
              <MenuItem value={v} key={i}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {newDiscount.valueType === '' && errorMessage}

        <hr className="hr" style={{ margin: '32px 0px' }} />
        <input
          onChange={onValueChanged}
          value={newDiscount.maxCount}
          name="maxCount"
          className="input"
          style={{ maxWidth: '100%' }}
          placeholder="Max count"
          type="number"
        />
        <input
          onChange={onValueChanged}
          value={newDiscount.expireOn}
          name="expireOn"
          className="input"
          style={{ maxWidth: '100%' }}
          placeholder="Expire on"
          type="date"
        />
        <input
          onChange={onValueChanged}
          value={newDiscount.ticker}
          name="ticker"
          className="input uppercase"
          style={{ maxWidth: '100%' }}
          placeholder="Ticker"
        />
        <UsersDropdown onUserUUID={onValueChanged} />
        {secondSectionIsEmpty() && secondErrorMessage}

        <button
          className="create-discount__button"
          onClick={createDiscount}
          disabled={!isReadyToCreate}
        >
          {createDiscountLoader ? <Loader size={30} /> : 'Create'}
        </button>
      </div>
    </div>
  );
};

export default CreateDiscount;
