export const buildMedallionsDisplay = (medallionsList: any) => {
  if (!medallionsList || !medallionsList.length) return [];

  const medallionsArray = [];

  let pendingMedallions = medallionsList?.filter(
    (m: any) => !m?.included
  );

  let completedMedallions = medallionsList?.filter(
    (m: any) => m?.included
  );

  medallionsArray.push(pendingMedallions);

  if (completedMedallions?.length > 0) {
    medallionsArray.push(completedMedallions);
  }

  return medallionsArray;
};

export const tableMedallionsTitles = [
  '',
  'Symbol',
  'Price',
  'Shares',
  'Market Value',
  'Status',
  'Actions',
];

export const completedTableMedallionsTitles = [
  'Symbol',
  'Price',
  'Shares',
  'Market Value',
  'Adjusted Price',
  'Status',
];

const fetchMedallions = (tr: any) => {
  return tr?.certificates?.reduce((acc: any, t: any, i: number) => {
    const currentIterateMedallions = t?.medallions.map((m: any) => {
      return {
        ...m,
        symbol: tr?.certificates[i].symbol,
        price: tr?.certificates[i].price,
        shares: tr?.certificates[i].shares,
      };
    });
    return [...acc, ...currentIterateMedallions].sort(sortApprovedFirst);
  }, []);
};

export const fetchMedallionsIds = (tr: any) => {
  return tr?.certificates?.reduce((acc: any, t: any, i: number) => {
    const currentIterateMedallions = t?.medallions.map((m: any) => m.uuid);
    return [...acc, ...currentIterateMedallions];
  }, []);
};

export const initialCheckedStatuses = (medallionsList: any) => {
  return medallionsList?.reduce((acc: any, m: any) => {
    acc[m?.uuid] = false;
    return acc;
  }, {});
};

export const extractedMedallionsIDs = (medallionsArr: any[]) => {
  if (!medallionsArr) return []
  return medallionsArr?.map((medallion: any) => medallion.uuid)
};

export const sortApprovedFirst = (a: any, b: any) => {
  if (a?.status === "APPROVED") {
    return -1; // a comes before b
  } else if (b?.status === "APPROVED") {
    return 1; // b comes before a
  } else {
    return 0; // leave the order unchanged
  }
};

export const certificatesHeaders = ["Symbol", "Shares", "Price", "Pending", "Denied", "Approved", ""];

export const calculatePendingMedallions = (transactionCertificates: any, uuid: any) => {
  const certificateToCalculate = transactionCertificates?.find((certificate: any) => {
    return uuid === certificate?.uuid
  });
  return certificateToCalculate?.pendingMedallionsCount;
}
export const calculateCompletedMedallions = (transactionCertificates: any, uuid: number) => {
  const certificateToCalculate = transactionCertificates?.find((certificate: any) => {
    return uuid === certificate?.uuid
  });
  return certificateToCalculate?.approvedMedallionsCount + certificateToCalculate?.deniedMedallionsCount;
}
