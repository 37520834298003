import { gql } from '@apollo/client';

export const LIST_RETURN_SHIPPINGS = gql`
  query listReturnShipings($transactionUUID: String!, $page: Float) {
    listReturnShipings(
      transactionUUID: $transactionUUID
      limit: 1000
      page: $page
    ) {
      items {
        uuid
        from {
          uuid
          addressLine1
          addressLine2
          state
          zip
        }
        to {
          uuid
          addressLine1
          addressLine2
          state
          zip
        }
        type
        provider
        createdAt
        status
      }
      total
      hasMore
    }
  }
`;
