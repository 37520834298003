import { useMutation } from '@apollo/client';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RESET_IDOLOGY } from '../../graphql/mutations/reset-idology.mutation';
import { UNLOCK_USER } from '../../graphql/mutations/unlock-user.mutation';
import { GET_USER } from '../../graphql/queries/get-user.query';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import IdologyExam from '../idology-exam/idology-exam.component';
import Loader from '../loader/loader.component';

import './user-idology.component.scss';

type userIdologyType = {
  user: any;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const UserIdology: React.FC<userIdologyType> = ({ user }) => {
  const [userUUID, setUserUUID] = useState<any>('');
  const dispatch = useDispatch();

  const location = useLocation();

  React.useEffect(() => {
    let arr = location.pathname.split('/');
    const uuid = arr[arr?.length - 1];
    setUserUUID(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [resetIdologyFunc, { loading: resetIdologyLoader, error: resetIdologyError }] =
    useMutation(RESET_IDOLOGY, {
      variables: {
        userUUID: userUUID,
      },
      refetchQueries: [
        {
          query: GET_USER,
          variables: { userUUID },
        },
      ],
      onCompleted: () => window.location.reload()
    });

  const [unlockUserFunc, { loading: unlockUserLoader, error: uuError }] =
    useMutation(UNLOCK_USER, {
      variables: {
        userUUID: userUUID,
      },
      onCompleted: () => resetIdologyFunc()
    });

  React.useEffect(() => {
    if (resetIdologyError?.message) {
      dispatch(triggerErr(resetIdologyError?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetIdologyError]);

  const openDialog = () => {
    if (window.confirm(`Are you sure you want to reset idology for ${user?.firstName} ${user.lastName}?`)) {
      try {
        if (user.status === "LOCKED") {
          unlockUserFunc();
        } else {
          resetIdologyFunc();
        }
      } catch (err) {
        alert(err)
      }
    }
  }

  const resetIdologyBtn = <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
    <span>Unlock idology (if user is locked) + Add another 3 attempts</span>
    <button className="reset-idology__btn" onClick={() => openDialog()}>
      {resetIdologyLoader || unlockUserLoader ? <Loader size={20} /> : 'Unlock user'}
    </button>
  </div>;

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, border: 'none', paddingBottom: 5 }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Unlock idology" {...a11yProps(0)} />
          <Tab label="Answer idology" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {resetIdologyBtn}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <IdologyExam userId={user?.uuid} />
      </TabPanel>
    </Box>
  );
};

export default UserIdology;
