/* eslint-disable no-use-before-define */
import { gql } from '@apollo/client';

export const GET_USER = gql`
  query getUser($userUUID: String!) {
    getUser(userUUID: $userUUID) {
      uuid
      firstName
      lastName
      email
      roles
      paymentMethod
      status
      dayOfBirth
      isEmailVerified
      mobile {
        uuid
        countryCode
        localNumber
        isVerified
      }
      homePhone {
        uuid
        countryCode
        localNumber
        isVerified
      }
      workPhone {
        uuid
        countryCode
        localNumber
        isVerified
      }
      home {
        uuid
        addressLine1
        addressLine2
        zip
        country
        state
        city
      }
      idologyLogs {
        uuid
        idNumber
        requestResponse
        status
        createdAt
        updatedAt
      }
      notes {
        uuid
        content
        createdAt
        author {
          email
          firstName
          lastName
        }
      }
    }
  }
`;
