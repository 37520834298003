import { gql } from '@apollo/client';

export const ACTIVATE_DISCOUNT = gql`
  mutation changeDiscountStatus($discountUUID: String!) {
    changeDiscountStatus(discountUUID: $discountUUID, status: ACTIVE) {
      uuid
      code
      valueType
      value
      status
      rules
    }
  }
`;
