import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { LIST_TRANSACTIONS } from '../../graphql/queries/list-transactions.query';
import Filters from '../filters/filters.component';
import PageTitle from '../page-title/page-title.component';
import SearchBox from '../search-box/search-box.component';
import Table, { ButtonActionType } from '../table/table.component';
import {
  getDefaultCheckIndex,
  tableTransactionsTitles,
  transactionsFilters,
} from './transactions.helper';
import moment from 'moment';

import './transactions.scss';
import { useNavigate } from 'react-router-dom';
import Loader from '../loader/loader.component';
import { useDispatch } from 'react-redux';
import { triggerErr } from '../../redux/slices/error-handling/error-handling-slice';
import { GET_ME } from '../../graphql/queries/get-me.query';
import { userLogin, userLogout } from '../../redux/slices/user/user-slice';

const DEFAULT_FILTER_AND_SORT: any = {
  filters: {}
}

const Transactions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const didMountRef = useRef(false);

  const [getMe, { loading: getMeLoader, error: getMeError }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (data?.getMe?.email) {
        dispatch(userLogin(data?.getMe));
        return;
      } else {
        dispatch(userLogout());
        navigate('/login', { replace: true });
      }
    },
  });

  React.useEffect(() => {
    try {
      getMe();
    } catch (err) {
      console.log('err ', err);
      navigate('/login', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [transactions, setTransactions] = useState([]);
  const [transactionsDisplay, setTransactionsToDisplay] = useState([]);
  const [page, setPage] = useState(0);
  const [isFetchByUploaded, setIsFetchByUploaded] = useState(false);
  const [defaultChecked, setDefaultChecked] = useState(0);
  const [search, setSearch] = useState('');
  const [isNext, setIsNext] = useState(false);
  const [filterAndSort, setFilterAndSort] = useState(DEFAULT_FILTER_AND_SORT);
  const [isReqReady, setIsReqReady] = useState(false);

  React.useEffect(() => {
    setFilterAndSort(DEFAULT_FILTER_AND_SORT);
  }, []);

  React.useEffect(() => {
    if (page === 1) {
      fetchTransactionsMethod();
      return;
    }
    setPage(1);
  }, [filterAndSort])

  React.useEffect(() => {
    if (page === 0) return;
    fetchTransactionsMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const onTableButtonsClicked = (action: ButtonActionType) => {
    let pageNumber = 0;
    if (action === ButtonActionType.BACK) {
      pageNumber = page - 1;
    }
    if (action === ButtonActionType.NEXT) {
      pageNumber = page + 1;
    }
    setPage(pageNumber);
  };

  React.useEffect(() => {
    if (didMountRef.current) {
      const timeoutId = setTimeout(() => setIsReqReady(true), 300);

      return () => {
        clearTimeout(timeoutId);
        setIsReqReady(false);
      };
    }

    didMountRef.current = true;
  }, [search]);

  React.useEffect(() => {
    if (isReqReady) {
      fetchTransactionsMethod();
    }
  }, [isReqReady])

  const buildFilters = () => {
    return { ...filterAndSort.filters, code: search.trim() }
  }

  const [
    fetchTransactions,
    { loading: getTransactionsLoader, error: gtError },
  ] = useLazyQuery(LIST_TRANSACTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setPageStates(data)
    },
  });

  const fetchTransactionsMethod = () => {
    fetchTransactions({
      variables: {
        pagination: { limit: 10, page: page },
        filter: buildFilters(),
        sort: filterAndSort.sort,
      }
    });
  };

  const setPageStates = (data: any) => {
    setTransactions(data?.listTransactions?.items);
    setTransactionsToDisplay(data?.listTransactions?.items);
    setIsNext(data?.listTransactions?.hasMore)
  };

  React.useEffect(() => {
    if (gtError?.message) {
      dispatch(triggerErr(gtError?.message));
    }
    if (getMeError?.message) {
      dispatch(triggerErr(getMeError?.message));
      navigate('/login', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtError, getMeError]);

  const onInputChange = (e: any) => {
    setSearch(e.target.value.trim());
  };

  const onFilterClicked = (e: string) => {
    setDefaultChecked(getDefaultCheckIndex(e));

    if (isFetchByUploaded) {
      setIsFetchByUploaded(false);
    }
    if (e === 'All') {
      setFilterAndSort({
        filters: {}
      });
      return;
    }
    if (e === 'Refunded') {
      setFilterAndSort({ filters: { paymentType: 'REFUND' } });
      return;
    }
    if (e === 'With declined medallions') {
      setFilterAndSort({ filters: { medallionStatus: 'DENIED' } });
      return;
    }
    if (e === 'With reviewing medallions') {
      setFilterAndSort({ filters: { medallionStatus: "IN_REVIEW" } });
      return;
    }
    if (e === 'Recently uploaded') {
      const today = moment();
      const sevenDaysAgo = moment().subtract(7, 'days');

      const startDate = sevenDaysAgo.format('YYYY-MM-DD');
      const endDate = moment(today).add(1, 'day').format('YYYY-MM-DD');

      setFilterAndSort({
        filters: { uploadedAtRange: { startDate, endDate } },
        sort: { uploadedAt: 'DESC' }
      });
      return;
    }
    if (e === 'With Refunds') {
      setFilterAndSort({ filters: { paymentType: 'REFUND' } });
      return;
    }
    if (e === 'All Medallions Declined') {
      setFilterAndSort({ filters: { allMedallionStatus: 'DENIED' } });
      return;
    }
    setFilterAndSort({ filters: { status: e.toUpperCase() } });
  };

  const tableBody = transactionsDisplay?.map((t: any) => (
    <tr key={t?.uuid}>
      <td className="table__body-transaction-id">
        <span
          className="table__transaction-id"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(`/transaction/${t?.uuid}`, { replace: true });
          }}
        >
          {t?.code}
        </span>
        <span className="table__issuer-name">
          by {t?.user?.firstName} {t?.user?.lastName}
        </span>
      </td>
      <td>
        <span className="table__body-td">{t?.certificates?.length}</span>
      </td>
      <td>
        <span className="table__body-td">
          {t?.certificates?.reduce((acc: number, certificate: any) => { acc += certificate?.medallionsCount; return acc; }, 0)}
        </span>
      </td>
      <td>
        <span className="table__body-td">
          {t?.charge?.totalMedallionCostInCents !== undefined && t?.charge?.totalShippingCostInCents !== undefined ? (
            <>
              $
              {(
                (t?.charge?.totalMedallionCostInCents +
                  t?.charge?.totalShippingCostInCents) /
                100
              ).toFixed(2)}
            </>
          ) : null}
        </span>

      </td>
      <td className="table__body-transaction-id">
        <span className="table__body-td">
          {moment(t?.createdAt).format('LL')}
        </span>
        <span className="table__issuer-name">
          {moment(t?.createdAt).format('LTS')}
        </span>
      </td>
      <td>
        <span className="table__body-td">
          {t?.status.charAt(0).toUpperCase() + t?.status.slice(1).toLowerCase()}
        </span>
      </td>
    </tr>
  ));

  return (
    <>
      {getMeLoader ? (
        <div
          style={{
            display: 'flex',
            margin: '0 auto',
            alignItems: 'center',
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="transactions right-panel-body">
          <PageTitle />
          <div style={{ marginTop: 40 }}>
            <SearchBox
              placeHolder="Transaction ID"
              onInputChange={onInputChange}
            />
            <div style={{ marginTop: 48 }}>
              <Filters
                filters={transactionsFilters}
                onFilterClicked={onFilterClicked}
                amount={[]}
                defaultChecked={defaultChecked}
              />
            </div>
            {getTransactionsLoader ? (
              <Loader />
            ) : (
              <div style={{ marginTop: 48 }}>
                <Table
                  titles={tableTransactionsTitles}
                  onButtonClicked={onTableButtonsClicked}
                  isNextPage={isNext}
                  isBackPage={page > 1}
                >
                  {tableBody}
                </Table>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Transactions;
