import React, { useEffect, useState } from 'react';

import './filters.scss';

type filtersType = {
  filters: string[];
  onFilterClicked: (filter: string) => void;
  amount: any[];
  defaultChecked?: number;
  reset?: number;
};

const Filters: React.FC<filtersType> = ({
  filters,
  onFilterClicked,
  amount,
  defaultChecked = 0,
  reset
}) => {
  const [isClicked, setIsClicked] = useState(0);
  const [shouldDisplayBottomLine, setShouldDisplayBottomLine] = useState(true);

  useEffect(() => {
    if (reset === 0) return;
    setShouldDisplayBottomLine(false)
  }, [reset]);

  return (
    <div className="filters">
      {filters.map((f: string, i: number) => (
        <div
          className="filters__tab"
          key={i}
          onClick={() => {
            onFilterClicked(f);
            setIsClicked(i);
            setShouldDisplayBottomLine(true);
          }}
        >
          <div>
            <span>{f}</span>
            {amount[i] !== undefined && (
              <span className="filters__amount">{amount[i]}</span>
            )}
          </div>
          {defaultChecked !== 0
            ? defaultChecked === i && shouldDisplayBottomLine && <div className="filters__bottom"></div>
            : isClicked === i && shouldDisplayBottomLine && <div className="filters__bottom"></div>}
        </div>
      ))}
    </div>
  );
};

export default Filters;
