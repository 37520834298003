import React, { useState } from 'react';
import { Collapse, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import moment from 'moment';
import { IdologyStatus, IdologyStatusObj } from '../idology-exam/idology-exam.constants';

const listItemStyles = {
    margin: "10px 0",
    padding: "5px",
    backgroundColor: "#f0f0f0",
    borderRadius: "5px",
};

const keyStyles = {
    fontWeight: "bold",
    marginRight: "5px",
};

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const DisplayJSON: React.FC<any> = ({ validations }) => {
    const [openArr, setOpenArr] = useState(validations.map((v: any, i: number) => ({
        idx: i,
        isOpen: false
    })));

    const handleToggle = (idx: number) => {
        let updatedOpenArr = openArr.map((open: any) => {
            if (open.idx === idx) {
                return {
                    idx,
                    isOpen: !open.isOpen
                }
            }
            return open;
        })
        setOpenArr(updatedOpenArr);
    };

    return (
        validations.map((v: any, i: number) => {
            const parser = new DOMParser();
            const xml = parser.parseFromString(
                v?.requestResponse,
                'text/xml'
            );
            const parsedData = parseNode(xml.documentElement)
            return <div>
                <div style={{ display: "flex", height: 50, marginBottom: 15, alignItems: "center", padding: "5px 15px", border: "3px solid #28DAB6", borderRadius: 15 }}>
                    <div style={{ marginRight: 30, display: "flex", justifyContent: "space-between", gap: 30, color: "#353535", opacity: 0.8 }}>
                        <Tooltip title={`ID: ${v.idNumber}`} placement='top'>
                            <span style={{ cursor: "pointer" }}>{v.idNumber}</span>
                        </Tooltip>
                        *
                        <Tooltip title={`Time created: ${moment(v?.createdAt).format("MM/DD/YYYY HH:mm:ss")}`} placement='top'>
                            <span style={{ cursor: "pointer" }}>{moment(v?.createdAt).format("MM/DD/YYYY HH:mm:ss")}</span>
                        </Tooltip>
                        *
                        <Tooltip title={`Status: ${IdologyStatusObj[v.status as IdologyStatus]}`} placement='top'>
                            <span style={{ cursor: "pointer" }}>{IdologyStatusObj[v.status as IdologyStatus]}</span>
                        </Tooltip>

                    </div>
                    <ExpandMore
                        expand={openArr[i].isOpen}
                        onClick={() => handleToggle(i)}
                        aria-expanded={openArr[i].isOpen}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </div>
                <Collapse in={openArr[i].isOpen}>
                    <div style={{ margin: "0 auto", width: "100%" }}>
                        {XMLParsed(parsedData, v)}
                    </div>
                </Collapse>
            </div>
        })
    );
};

export default DisplayJSON;

const XMLParsed = (data: any, validation: any) => {
    return <div style={{ padding: "5px 15px", border: "3px solid #28DAB6", borderRadius: 15, marginBottom: 15 }}>
        {displayJSONMethod(data)}
    </div>
}

const displayJSONMethod = (data: any) => {
    const entries = Object.entries(data);

    return <ul style={{ listStyleType: 'none', padding: 0 }}>
        {entries.map(([key, value]) => {
            return (
                <li key={key} style={listItemStyles}>
                    {key !== "#text" && <span style={keyStyles}>{key}:</span>}
                    {typeof value === "object" ? (
                        <>{displayJSONMethod(value)}</>
                    ) : (
                        <span>{data[key]}</span>
                    )}
                </li>
            );
        })}
    </ul>
}

function parseNode(node: any) {
    if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent.trim();
    } else if (node.nodeType === Node.ELEMENT_NODE) {
        const obj: any = {};
        for (let i = 0; i < node.attributes.length; i++) {
            const attr = node.attributes[i];
            obj[attr.name] = attr.value;
        }
        for (let i = 0; i < node.childNodes.length; i++) {
            const childNode = node.childNodes[i];
            const childObj = parseNode(childNode);
            const tagName = childNode.nodeName;
            if (!obj[tagName]) {
                obj[tagName] = childObj;
            } else if (Array.isArray(obj[tagName])) {
                obj[tagName].push(childObj);
            } else {
                obj[tagName] = [obj[tagName], childObj];
            }
        }
        return obj;
    } else {
        return null;
    }
}