import { gql } from '@apollo/client';

export const DISABLE_DISCOUNT = gql`
  mutation changeDiscountStatus($discountUUID: String!) {
    changeDiscountStatus(discountUUID: $discountUUID, status: DISABLED) {
      uuid
      code
      valueType
      value
      status
      rules
    }
  }
`;
