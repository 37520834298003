import { TabType } from '../navigator/navigator.component';

export const navigatorDetails: TabType[] = [
  { name: 'Transactions', navigateTo: '/transactions', isLeaf: true },
  { name: 'Transaction Details', navigateTo: '', isLeaf: false },
];

export const transactionDetailsFilters = [
  'Medallions',
  'Files',
  'Notes',
  'Shipping Information',
  'Payment / Refund',
  'Verifications',
];

export const tableTransactionDetailsTitles = [
  'Requirements',
  'Documents',
  'Status',
];

export const isFileUploaded = (d: any) => {
  return d?.status === 'UPLOADED' || d?.status === 'SIGNED';
};

export const notReadyForApproveStatuses = [
  'COMPLETED',
  'SUSPICIOUS',
  'CANCELLED',
  'DELETED',
  'CREATING',
  'REVIEWING',
];

export const transactionReadyForReview = (status: string) => {
  return notReadyForApproveStatuses.includes(status);
};

export const getMedallionsLength = (transaction: any) => {
  return transaction?.certificates?.reduce((acc: any, certificate: any) => {
    return (acc += certificate?.medallions?.length);
  }, 0);
};
