/* eslint-disable no-use-before-define */
import { gql } from '@apollo/client';

export const GET_FEDEX_RATE = gql`
  query getReturnFedexRate($fedexServiceUUID: String!, $addressUUID: String!) {
    getReturnFedexRate(
      addressUUID: $addressUUID
      fedexServiceUUID: $fedexServiceUUID
    ) {
      serviceType
      packagingType
      totalDiscounts
      totalBaseCharge
      totalNetCharge
      totalNetFedExCharge
    }
  }
`;
